<template>
  <v-row class="bg-primary h-100 m-auto d-flex flex-column">
    <v-col cols="12" class="m-auto" style="height: 100%; justify-content: space-evenly">
      <v-card id="terms" class="px-2" style="display: grid; text-align: justify">
        <v-card-title class="h4 m-auto" style="text-wrap: wrap !important"
          >CONDIZIONI GENERALI DEL SERVIZIO “LEGENDA”
        </v-card-title>
        <v-card-text>
          <ol>
            <li>
              <strong>Oggetto</strong>
              <ol>
                <li>
                  Le presenti condizioni generali di contratto regolano il rapporto tra
                  Metis Srl, con sede in Milano, Piazza Sicilia 8, (“Metis”) e l’utente
                  del servizio Legenda (rispettivamente “l’Utente” e “il Servizio”).
                  <ol>
                    <li>
                      Il contratto tra Metis e l’Utente si considera concluso solo al
                      momento della specifica approvazione delle clausole di cui ai punti
                      1.5, 1.6, 1.8, 2.2, 2.3, 2.5, 3.6, 5.1, 6.2, 7.1 e 7.2, anche
                      qualora tale approvazione avvenga in un momento successivo
                      all’approvazione delle presenti condizioni generali ed alla
                      esecuzione della controprestazione dell’utente di cui al punto 1.6.
                      Rimane quindi inteso che ove l’Utente non approvi specificamente le
                      clausole di cui sopra Metis non erogherà il Servizio e l’Utente avrà
                      diritto alla restituzione della controprestazione eventualmente
                      eseguita.
                    </li>
                  </ol>
                </li>
                <li>
                  Il Servizio è costituito da una web – app che esegue la ricerca di
                  contenuti all’interno di documenti informatici caricati dall’Utente,
                  sulla base di input di ricerca inseriti dall’Utente (“Query”).
                </li>
                <li>
                  Il Servizio, in particolare, esegue una indicizzazione automatica dei
                  contenuti dei documenti informatici – previo riconoscimento c.d. OCR in
                  caso di documenti in formato immagine – al momento dell’upload da parte
                  dell’Utente e successivamente, al momento dell’inserimento della Query,
                  restituisce all’Utente dei risultati consistenti nel riferimento ai
                  contenuti di singoli documenti informatici caricati dall’Utente,
                  ordinati per rilevanza rispetto alla Query.
                </li>
                <li>
                  Il Servizio viene erogato all’Utente mediante l’attivazione da parte di
                  Metis di una coppia di credenziali (nome utente e password, “le
                  Credenziali”) che consentono l’accesso e l’utilizzo della web – app
                  descritta ai punti che precedono.
                </li>
                <li>
                  L’Utente è obbligato a conservare con cura le Credenziali e a non
                  cederle a terzi, per nessun motivo, senza autorizzazione di Metis. In
                  caso di smarrimento, furto o compromissione è obbligato a comunicarlo a
                  Metis onde consentirne la sostituzione, salva la valutazione di
                  responsabilità dell’Utente ai fini dell’eventuale risoluzione del
                  contratto.
                </li>
                <li>
                  L’attivazione delle Credenziali, se il Servizio prevede il pagamento di
                  un corrispettivo da parte dell’Utente o l’esecuzione di una diversa
                  controprestazione da parte sua (obbligazioni dell’Utente che possono
                  risultare dagli accordi tra Metis e l’Utente o dalle condizioni
                  riportate dalla pagina web del Servizio in particolare durante la
                  procedura di attivazione delle Credenziali), è subordinata
                  all’esecuzione di tale controprestazione. Metis si riserva pertanto il
                  diritto di sospendere o definitivamente disattivare le Credenziali,
                  anche successivamente all’attivazione, anche senza preavviso, ove
                  riscontri, tra l’altro, l’inadempimento di tale obbligazione
                  dell’Utente.
                </li>
                <li>
                  In ogni caso di caducazione del contratto, ivi compresa la scadenza del
                  termine finale, il recesso di qualsiasi parte, se ammesso, la
                  risoluzione o rescissione, così come in caso di declaratoria di nullità
                  o annullamento, le Credenziali verranno immediatamente disattivate.
                </li>
                <li>
                  La durata del Servizio e, quindi, delle Credenziali, è disciplinata
                  dagli accordi tra Metis e l’Utente o dalle condizioni riportate dalla
                  pagina web del Servizio in particolare durante la procedura di
                  attivazione delle Credenziali. In mancanza di previsione di un termine
                  finale, entrambe le parti potranno recedere in qualsiasi momento con
                  preavviso di un giorno lavorativo.
                </li>
              </ol>
            </li>

            <li>
              <strong>Aspetti particolari del Servizio</strong>
              <ol>
                <li>
                  Il Servizio, come descritto in particolare ai punti 1.2 e 1.3 che
                  precedono, funziona mediante l’esecuzione di software, lato server,
                  sviluppato da Metis e che utilizza algoritmi c.d. di intelligenza
                  artificiale (“IA”) nella fase di indicizzazione e in quella successiva
                  di ricerca della Query dell’Utente. Il Servizio, inoltre, consente di
                  procedere alla ricerca sulla base di Query non strettamente basate su
                  parole-chiave, mediante l’interpretazione della richiesta dell’Utente
                  grazie alla IA.
                </li>
                <li>
                  Tanto premesso, l’Utente prende pertanto atto che Metis, pur
                  obbligandosi a mantenere attive le Credenziali per la durata del
                  rapporto con l’Utente e a garantire l’operatività del Servizio (ferma la
                  necessità che l’Utente si doti, a propria cura e spese, dei dispositivi
                  e delle risorse tecnologiche necessarie per accedere al Servizio), non
                  provvede direttamente all’indicizzazione e ricerca descritte e non si
                  obbliga quindi a fornire un livello minimo di affidabilità dei risultati
                  della ricerca, anche con riguardo alla completezza o pertinenza dei
                  risultati forniti.
                </li>
                <li>
                  Metis non potrà quindi in alcun modo essere ritenuta responsabile a
                  qualsivoglia titolo per i risultati di ricerca forniti dal Servizio e
                  ciò a prescindere dalla Query inserita dall’Utente, che è quindi reso
                  consapevole della necessità di verificare i risultati ottenuti mediante
                  tecniche di ricerca tradizionali, a propria esclusiva cura.
                </li>
                <li>
                  Fermo quanto sopra, Metis si adopererà comunque per mantenere un’elevata
                  l’affidabilità del Servizio adottando tecnologie in linea con lo stato
                  dell’arte. In quest’ottica l’Utente prende atto che il Servizio potrà
                  subire modifiche estetiche, funzionali o di altro tipo durante la durata
                  del rapporto.
                </li>
                <li>
                  In considerazione della natura altamente tecnologica del Servizio,
                  l’Utente prende atto che per interventi di manutenzione, programmata o
                  urgente, così come per altri eventi non prevedibili, il Servizio possa
                  subire delle interruzioni temporanee. In caso di interruzione del
                  Servizio di durata superiore alle 48 ore, e solo in caso di contratto a
                  termine ai sensi del punto 1.8 che precede, il contratto con l’Utente
                  verrà esteso di una durata pari al tempo dell’interruzione. Metis si
                  adopererà in ogni caso al fine di dare tempestivo avviso di ogni
                  interruzione tramite la pagina web del Servizio.
                </li>
              </ol>
            </li>

            <li>
              <strong>Documenti informatici e proprietà intellettuale</strong>
              <ol>
                <li>
                  Il Servizio come descritto ai punti 1.2 e 1.3 che precedono è basato,
                  tra l’altro, sul caricamento (upload) di documenti informatici da parte
                  dell’Utente nelle aree di memoria messe a disposizione da Metis per il
                  funzionamento del Servizio.
                </li>
                <li>
                  L’Utente garantisce espressamente la liceità dei documenti informatici
                  così caricati e del loro contenuto, così come garantisce di avere pieno
                  diritto a trattarli, riconoscendo di esserne responsabile in modo
                  esclusivo e manlevando Metis da qualsivoglia responsabilità in merito.
                </li>
                <li>
                  L’Utente riconosce che l’utilizzo del Servizio richiede che i sistemi di
                  Metis accedano ai documenti informatici caricati dall’Utente e che ai
                  fini esclusivi di assistenza e sviluppo personale tecnico di Metis possa
                  accedere ai medesimi documenti: con il caricamento dei documenti
                  informatici tramite la web app l’Utente autorizza espressamente tale
                  accesso.
                </li>
                <li>
                  Metis si obbliga in ogni caso a mantenere sicuro l’accesso ai documenti
                  informatici caricati dall’Utente, a non divulgarli e a non accedervi per
                  ragioni diverse da quelle menzionate nel punto che precede. Un riepilogo
                  delle misure di sicurezza adottate a tal fine dall’Utente può essere
                  consultato sulla pagina web del servizio.
                </li>
                <li>
                  Fermi i diritti esclusivi dell’Utente sul contenuto dei documenti
                  informatici caricati e sul loro contenuto, con le relative
                  responsabilità, l’Utente riconosce espressamente di non avere alcun
                  diritto di proprietà intellettuale o industriale o di altro tipo su
                  software, algoritmi di ricerca, risultati delle ricerche, o qualsiasi
                  altra parte, tecnologia, fase o prodotto in qualunque modo legati al
                  Servizio, eccettuato l’accesso e l’utilizzo dello stesso limitatamente
                  al periodo di durata del Servizio di cui al punto 1.8 che precede.
                </li>
                <li>
                  L’Utente si impegna ad utilizzare il Servizio esclusivamente per la
                  propria attività professionale o imprenditoriale, si obbliga in
                  particolare a non utilizzare il servizio per fini commerciali in
                  concorrenza con l’attività di Metis e a non divulgare i risultati delle
                  ricerche o eventuali dettagli sul funzionamento del Servizio o a
                  utilizzare il nome o marchi, anche non registrati, di Metis senza
                  l’autorizzazione scritta di Metis.
                </li>
                <li>
                  Il Servizio è erogato, tramite una web – app, nella forma c.d. software
                  as a service: l’Utente non ha in nessun caso diritto a copie, fisiche o
                  digitali, dei software utilizzati da Metis per fornire il Servizio, né
                  ha diritto all’accesso, visione o conoscenza, in qualunque forma e per
                  qualunque motivo, del codice sorgente, degli algoritmi di ricerca o di
                  qualsiasi altra parte, tecnologia o funzione del Servizio, che rimangono
                  di esclusiva proprietà di Metis.
                </li>
                <li>
                  L’Utente si obbliga a conservare copie di ogni documento caricato e
                  riconosce altresì che il Servizio ha esclusivamente le finalità
                  descritte ai punti 1.2 e 1.3 che precedono: non rientra tra le
                  obbligazioni di Metis quella di fornire servizi di hosting o di backup
                  dei documenti informatici dell’Utente al di fuori di quanto strettamente
                  necessario al funzionamento del Servizio come sopra descritto.
                </li>
              </ol>
            </li>
            <li>
              <strong>Clausola risolutiva espressa</strong>
              <ol>
                <li>
                  Darà diritto alla risoluzione del contratto ai sensi dell’art. 1456 c.c.
                  l’inadempimento da parte dell’Utente delle obbligazioni di cui al punto
                  1.5 (conservazione delle credenziali), 1.6 (pagamento del corrispettivo
                  o della controprestazione), 3.2 (caricamento di documenti illeciti o
                  senza diritto), 3.6 (usi non consentiti del Servizio). Il medesimo
                  diritto è riconosciuto all’Utente in caso di interruzione del Servizio
                  per un periodo di tempo superiore a 7 giorni consecutivi, se
                  l’interruzione in questione ha carattere continuativo o comunque
                  reiterato in modo tale da rendere sostanzialmente impossibile l’accesso
                  al Servizio per tutta la durata predetta.
                </li>
              </ol>
            </li>
            <li>
              <strong>Divieto di cessione</strong>
              <ol>
                <li>
                  L’Utente non potrà sostituire a sé terzi nei rapporti derivanti dal
                  contratto con Metis, né cedere a terzi, in tutto o in parte, i diritti
                  derivanti dal contratto stesso, così come le Credenziali ricevute.
                </li>
              </ol>
            </li>
            <li>
              <strong>Comunicazioni</strong>
              <ol>
                <li>
                  Tutte le comunicazioni relative al contratto tra l’Utente e Metis
                  verranno inviate a mezzo PEC a Metis all’indirizzo
                  1metissrl@legalmail.it e all’Utente all’indirizzo PEC che questi si
                  obbliga a fornire in fase di registrazione o successivamente ad essa.
                </li>
                <li>
                  L’eventuale mancata indicazione da parte dell’Utente di un indirizzo PEC
                  legittima Metis a comunicare a mezzo email non certificata,
                  all’indirizzo fornito in fase di registrazione, senza che l’Utente possa
                  eccepire la mancata ricezione.
                </li>
              </ol>
            </li>
            <li>
              <strong>Competenza, giurisdizione, legge applicabile</strong>
              <ol>
                <li>
                  Qualsiasi controversia relativa alla interpretazione, esecuzione o
                  risoluzione del contratto tra l’Utente e Metis, o ad esso in qualunque
                  modo collegata, è devoluta in via esclusiva alla giurisdizione
                  dell’Autorità Giudiziaria italiana, con competenza esclusiva radicata
                  nel Foro di Milano, con espressa esclusione di ogni altro Foro
                  concorrente.
                </li>
                <li>
                  Le presenti condizioni generali, così come il contratto tra l’Utente e
                  Metis, in ogni loro aspetto, comprese interpretazione, validità,
                  esecuzione, sono regolate in via esclusiva dalla legge italiana.
                </li>
              </ol>
            </li>
            <li>
              <strong>Erogazione del Servizio per il tramite di Terze Parti</strong>
              <ol>
                <li>
                  Qualora il Servizio, anche se organizzato da Metis, venga fornito
                  all’Utente per il tramite di soggetti diversi da Metis o su pagine web
                  non riconducibili in modo diretto a Metis, ma a soggetti diversi, l’uso
                  del Servizio sarà disciplinato dagli accordi tra l’Utente e tali
                  soggetti diversi.
                </li>
                <li>
                  L’Utente sarà in ogni caso obbligato anche nei confronti di Metis a
                  rispettare le previsioni 1.5, 3.2, 3.6, 3.8, 6.1, 6.2, 7.1 e 7.2 ove
                  applicabili.
                </li>
              </ol>
            </li>
          </ol>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TermsConditionsView",
};
</script>

<style>
#terms ol {
  counter-reset: item;
  padding: 0.5em;
}
#terms li {
  display: block;
  margin: 0.5em 0;
}
#terms li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  font-weight: bold;
}
</style>
