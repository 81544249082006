<template>
  <div v-if="pdfBinary.byteLength > 0">
    <div class="ipbrary-flex-column">
      <v-sheet
        elevation="0"
        class="my-2 px-3 flex-grow-1 h-100 d-flex flex-column justify-space-evenly"
        rounded="lg"
      >
        <div class="p-2 h-10">
          <strong class="font-italic font-size-large"
            >{{ this.fileName }}
          </strong>
        </div>

        <div style="height: 88%" class="p-1">
          <!--pdfbinary take from state-->
          <pdfViewerCmp
            :binaryFile="pdfBinary"
            :page="pageToShow"
            :pageScale="scale"
            v-on:pageChanged="resetPage"
          />
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import PDFJSViewer from "@/components/document/PDFJSViewer";

export default {
  name: "DocumentViewer",
  components: {
    pdfViewerCmp: PDFJSViewer,
  },
  props: {
    fileName: {
      default: null,
      type: String,
    },
    pdfBinary: {
      type: ArrayBuffer,
      default() {
        return new ArrayBuffer(0);
      },
    },
    pageToShow: {
      type: Number,
      default: 1,
    },
    scale: {
      type: String,
      default: "page-fit",
    },
  },
  data() {
    return {};
  },
  methods: {
    resetPage() {
      this.$emit("resetPage");
    },
  },
};
</script>
<style></style>
