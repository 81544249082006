<template>
  <v-app>
    <spinner></spinner>
    <navBar v-if="isAuthenticated" />
    <!-- <navBar /> -->

    <v-navigation-drawer app v-model="drawer" clipped permanent class="sidebar" v-if="isAuthenticated">
      <!-- Sidebar content here -->
      <div class="library-section">
        <div class="section-header">

          <span>My Library</span>
          <v-icon>mdi-plus-circle-outline</v-icon>

        </div>

        <v-list>
          <v-list-item v-for="(patent, index) in patents" :key="index" class="library-item">
            <v-list-item-avatar>
              <v-img :src="patent.image" class="patent-image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>{{ patent.name }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-divider color="#DEE1E6"></v-divider>
      <div class="saved-section">
        <div class="section-header">

          <span>Saved Patents</span>
          <v-icon>mdi-plus-circle-outline</v-icon>

        </div>
        <v-list>
          <v-list-item v-for="(saved, index) in savedPatents" :key="index" class="saved-item">
            <v-list-item-content>{{ saved.name }}</v-list-item-content>

            <div class="icons-wrp">

              <v-btn icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </div>

          </v-list-item>
        </v-list>

        <v-divider color="#DEE1E6FF"></v-divider>


        <div class="section-header">

          <span>Saved Collections</span>

          <v-icon>mdi-plus-circle-outline</v-icon>

        </div>
        <v-list>
          <v-list-item v-for="(collection, index) in collections" :key="index" class="saved-item">
            <v-list-item-content>{{ collection.name }}</v-list-item-content>
            <div class="icons-wrp">

              <v-btn icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </div>
          </v-list-item>
        </v-list>
      </div>
      <v-divider color="#DEE1E6FF"></v-divider>

      <div class="searches-section">
        <div class="section-header">

          <span>Recent Searches</span>
          <v-icon>mdi-magnify-plus-outline</v-icon>

        </div>
        <v-list>
          <v-list-item v-for="(search, index) in recentSearches" :key="index" class="searches-item">
            <v-list-item-avatar>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <div class="info-wrp">
                <div class="search-type"> {{ search.type }}</div>
                <span class="search-name"> {{ search.name }}</span>
              </div>
            </v-list-item-content>


          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
  </v-app>
</template>



<script>
// @ is an alias to /src
import NavBar from "@/components/ui/NavBar.vue";
import Spinner from "@/components/ui/Spinner.vue";
import patentImg1 from "@/assets/images/patents_img/Image20.png";
import patentImg2 from "@/assets/images/patents_img/Image21.png";
import patentImg3 from "@/assets/images/patents_img/Image22.png";

export default {
  components: {
    navBar: NavBar,
    spinner: Spinner,
  },
  data() {
    return {
      credentialPaths: [
        "/home",
        "/profile",
        "/content",
        "/search",
        "/components",
        "/componentSearch",
      ],
      drawer: true,
      patents: [
        { name: "New Patent 1", image: patentImg1 },
        { name: "New Patent 2", image: patentImg2 },
        { name: "New Patent 3", image: patentImg3 },
      ],
      savedPatents: [
        { name: "Patent 1" },
        { name: "Patent 2" },
      ],
      collections: [
        { name: "Collection 1" },
        { name: "Collection 2" },
      ],
      recentSearches: [
        { name: "method to process graphite ", type: 'Search' },
        { name: "robotic for industry automation", type: 'Patent Collection' },
        { name: "AI sensor", type: 'Patent Collection' },
      ],
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.checkContentPath();
    },
  },
  methods: {
    checkContentPath() {
      if (
        this.$router.currentRoute.value.fullPath == "/" ||
        this.$router.currentRoute.value.name == "PageNotFound"
      ) {
        return false;
      }

      const contentPath = this.credentialPaths.filter((element) =>
        this.$router.currentRoute.value.path.includes(element)
      );

      return contentPath.length > 0;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  overflow: auto !important;
  font-family: Lexend, Helvetica, Arial, sans-serif !important;

}

.v-main {
  background: #F3F4F6FF !important;
}

.v-btn {
  text-transform: unset !important;
}

#app {
  font-family: Lexend, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.paddingForNavbar {
  padding-top: 80px;
}

.fill-row-height {
  height: 100vh;
}

.align-content-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.align-content-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar {
  padding: 16px;
  padding-top: 25px;
  width: 240px;
  top: 80px !important;
  /* Aggiorna il valore top in base all'altezza della navbar */
}

/* .library-section,
.saved-section {
  margin-bottom: 20px;
} */

.searches-section {}

.library-section,
.saved-section .v-list {
  margin-top: 10px !important;
}

.library-item .v-list-item__content {
  display: flex;
  align-items: center;
}

.searches-item .v-list-item__content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.library-item,
.saved-item,
.collection-item,
.searches-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
}

.library-item:last-child,
.saved-item:last-child,
.collection-item:last-child,
.searches-item:last-child {
  margin-bottom: 0 !important;
}

.v-divider {
  border-width: 1px !important;
}

.saved-item .v-list-item__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  background: #F3F4F6FF;
  border-radius: 20px;
  padding: 0 10px;
  width: 100%;
}

.saved-item .v-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 12px !important;
  width: 18px !important;
  height: 18px !important;
  line-height: 18px !important;
}

.library-item .patent-image,
.saved-item .patent-image,
.collection-item .patent-image {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.searches-item .info-wrp {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.searches-item .search-name {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

.searches-item .search-type {
  font-size: 11px;
  line-height: 18px;
  font-weight: 400;
  color: #FF56A5FF;
  background: #00000000;
  border-color: #FF56A5FF;
  border-width: 1px;
  border: 1px solid;
  border-radius: 26px;
  width: fit-content;
  /* max-width: 120px; */
  padding: 0 10px;
}

.searches-item .v-icon {
  background: #F3F4F6FF;
  /* neutral-200 */
  border-radius: 26px;
  width: 40px;
  height: 40px;
  fill: #323743FF;
  padding: 10px;
}
</style>
