import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import users from "./modules/users";
import pdfs from "./modules/pdfs";
import searches from "./modules/searches";
import folders from "./modules/folders";
import loading from "./modules/loading";
import llm from "./modules/llm";
import imageSearch from "./modules/imageSearch";
import searchQueries from "./modules/searchQueries";

import _ from "lodash";

export const initialStoreModules = {
  users,
  pdfs,
  searches,
  folders,
  loading,
  llm,
  imageSearch,
  searchQueries,
};

const store = createStore({
  modules: _.cloneDeep(initialStoreModules),
  mutations: {
    // reset default state modules by looping around the initialStoreModules
    resetState(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
      });
      store.commit("setLoading", false);
      localStorage.removeItem("pdfjs.history");
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

export default store;
