<template>
  <div class="panel-style">
    <div class="panel-header">
      <div class="search-context" v-if="showContext">
        <p class="text-h5" v-html="searchContext"></p>
      </div>
      <div class="panel-button">
        <v-btn
          color="primary"
          :prepend-icon="'mdi-delete-outline'"
          variant="elevated"
          class="m-1"
          @click="deleteElements()"
          :disabled="selectedPanels.length == 0"
          >{{ this.$filters.translate("ipbrary_041") }}</v-btn
        >
      </div>
    </div>

    <v-expansion-panels class="panel-wrapper" variant="accordion">
      <v-expansion-panel
        v-for="panel in panelData.panels"
        :key="panel.id"
        :value="panel.id"
        class="m-2 p-2"
      >
        <v-progress-linear
          :active="panel.loading"
          :color="panel.progressColor"
          height="4"
          indeterminate
        ></v-progress-linear>

        <v-expansion-panel-title class="p-0 justify-content-center w-100">
          <div
            class="d-flex flex-row align-items-center flex-wrap w-100"
            style="justify-content: space-between"
          >
            <div class="d-flex flex-row">
              <v-checkbox-btn
                v-model="panel.checked"
                @update:model-value="selectAll(panel)"
              ></v-checkbox-btn>
              <div class="align-content-center">
                <div v-if="!panel.editing">
                  {{ panel.name }}
                </div>

                <v-form
                  style="min-width: 180px"
                  v-if="panel.editing"
                  @submit.prevent="this.$emit('updateTitle', panel)"
                >
                  <v-text-field
                    density="compact"
                    :ref="`panelTitleInput${panel.id}`"
                    variant="underlined"
                    autocomplete="off"
                    required
                    hide-details
                    :readonly="inputReadonly(panel)"
                    :rules="panel.rules"
                    v-model="panel.name"
                    :prepend-inner-icon="panel.icon"
                    @click.stop
                    @keyup.prevent
                  ></v-text-field>
                </v-form>
              </div>
            </div>

            <div class="d-flex flex-row mx-1 px-1 justify-content-end">
              <div
                class="fit-content"
                v-for="action in panel.panelActions"
                :key="`${action.actionName}_${panel.id}`"
                :value="`${action.actionName}_${panel.id}`"
              >
                <v-btn
                  v-if="!action.editing"
                  :icon="action.icon"
                  color="button-primary"
                  variant="text"
                  :title="action.title"
                  @click.native.stop="panelActionClicked(panel, action)"
                >
                </v-btn>

                <v-form
                  style="min-width: 180px"
                  v-if="action.editing && action.editable"
                  @submit.prevent="
                    this.$emit(action.actionName, {
                      panel: panel,
                      action: action,
                    })
                  "
                >
                  <v-text-field
                    density="compact"
                    variant="underlined"
                    autocomplete="off"
                    required
                    hide-details
                    clearable
                    v-model="action.searchInput"
                    :prepend-inner-icon="action.icon"
                    @click:clear="action.editing = false"
                    @click.stop
                    @keyup.prevent
                  ></v-text-field>
                </v-form>
              </div>
            </div>
          </div>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <list
            v-model="panel.items"
            :items="topElements(panel)"
            @itemChecked="updateSelectedPanels($event, panel)"
            @actionClicked="
              this.$emit('resultActionClicked', {
                actionObject: $event,
                panel: panel,
              })
            "
            @listItemClicked="
              this.$emit('resultItemClicked', { result: $event, panel: panel })
            "
          ></list>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ConfigurableListComponent from "@/components/ui/ConfigurableListComponent.vue";
export default {
  name: "ExpansionPanelComponent",
  components: {
    list: ConfigurableListComponent,
  },
  props: {
    panelData: {
      type: Object,
      default() {
        return { panels: [], searchContext: null };
      },
    },
    showContext: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    panelData: {
      handler() {},
      deep: true,
    },
  },
  computed: {
    /**
     *
     */
    searchContext() {
      const htmlText =
        " <strong class='font-italic' style='display: contents'>" +
        this.panelData.searchContext +
        " </strong>";

      const text = this.$filters
        .translate("ipbrary_052")
        .replace("_placeholder", htmlText);

      return text;
    },
  },
  methods: {
    inputReadonly(item) {
      if (item.editing) {
        this.$nextTick(() => {
          const ref = this.$refs[`panelTitleInput${item.id}`];
          ref[0].focus();
        });
        return false;
      } else {
        return true;
      }
    },
    /**
     *
     * @param {*} panel
     * @param {*} action
     */
    panelActionClicked(panel, action) {
      //if we need to activate the input field
      if (action.editable) {
        panel.panelActions.find((panelAction) => {
          if (panelAction.actionName === action.actionName)
            panelAction.editing = true;
        });
      } else {
        // just emit the action
        this.$emit(action.actionName, panel);
      }
    },
    /**
     *
     * @param {*} itemChecked
     * @param {*} panel
     */
    updateSelectedPanels(itemChecked, panel) {
      // check if item was checked or unchecked
      if (itemChecked.checked) {
        panel.checked = itemChecked.checked;

        // push the whole panel if not
        if (
          this.selectedPanels.length == 0 ||
          this.selectedPanels.some((p) => p.id != panel.id)
        ) {
          this.selectedPanels.push({
            items: [{ ...itemChecked }],
            id: panel.id,
          });
        } else {
          //push only the element
          this.selectedPanels.filter((p) => {
            if (panel.id == p.id) {
              p.items.push({ ...itemChecked });
            }
          });
        }
      } else {
        //remove the element from the items list
        const panelToUpdate = this.selectedPanels.find(
          (p) => p.id === panel.id
        );
        panelToUpdate.items = [
          ...panelToUpdate.items.filter(
            ({ ...item }) => item.id !== itemChecked.id
          ),
        ];

        // if this panel has no longer selected items, remove it from the selectedPanelsArray
        if (panelToUpdate.items.length == 0) {
          this.selectedPanels = [
            ...this.selectedPanels.filter((p) => {
              p.id !== panel.id;
            }),
          ];
          panel.checked = itemChecked.checked;
        }
      }
    },
    /**
     *
     */
    deleteElements() {
      this.$emit("deleteSelected", this.selectedPanels);
      this.selectedPanels = [];
    },
    /**
     *
     * @param {*} panel
     */
    selectAll(panel) {
      this.$emit("panelChecked", panel);
      if (panel.checked) {
        this.selectedPanels.push({
          items: [...panel.items],
          id: panel.id,
        });
      } else {
        this.selectedPanels = this.selectedPanels.filter(
          (p) => p.id != panel.id
        );
      }
    },
    /**
     *
     * @param {*} panel
     */
    topElements(panel) {
      return panel.items;
    },
  },
  data() {
    return {
      selectedPanels: [],
    };
  },
};
</script>
<style>
.panel-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.panel-style {
  display: flex;
  flex-direction: row;
  margin: 0 1%;
  padding: 0.5%;
  flex-wrap: wrap;
}
.panel-button {
  max-width: 200px;
  margin: 0 1%;
  justify-content: flex-end;
}
.panel-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2%;
  max-height: 80vh;
  margin: 1%;
}
</style>
