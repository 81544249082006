<template>
  <baseSearchResultsView
    v-model="componentConfig"
    :createPayload="componentConfig.createPayload"
    :documentId="documentId"
  />
</template>

<script>
import configFactory from "../../../components/search/inspectSearch/services/inspectSearchConfiguration.js";
import BaseSearchResults from "./BaseSearchResults.vue";

export default {
  name: "SearchResults",

  components: {
    baseSearchResultsView: BaseSearchResults,
  },
  data() {
    return {
      componentConfig: null,
      documentId: null,
    };
  },
  created() {
    // created methods according to the search mode
    this.documentId = window.history.state.document_id;
    this.componentConfig = configFactory.createConfig(this.documentId);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setPdfBinary", new ArrayBuffer(0)); //take out
    next();
  },
};
</script>
