<template>
  <v-dialog v-model="dialog" contained location="top">
    <v-card class="w-auto h-auto m-auto p-2 rounded-0">
      <template v-slot:title>
        <div class="d-flex">
          <v-icon :icon="icon" color="primary" class="me-1 pb-1"></v-icon>
          <h4>{{ title }}</h4>
        </div>
      </template>

      <v-form v-model="isFormValid" @submit.prevent="onOk">
        <v-card-text>
          <v-text-field
            style="width: 300px"
            :rules="rules"
            variant="solo"
            :label="label"
            v-model="value"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="justify-content-center">
          <v-btn
            variant="plain"
            color="button-secondary"
            @click="onCancel"
            class="mr-2"
            >Cancel</v-btn
          >

          <v-btn
            color="button-primary"
            variant="flat"
            type="submit"
            :disabled="!isFormValid"
            >{{ okButtonLabel }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddModal",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },

    okButtonLabel: {
      type: String,
      default: "OK",
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      isFormValid: false,
      value: null,
    };
  },
  methods: {
    onOk() {
      this.$emit("ok", this.value);
      this.value = null;
    },
    onCancel() {
      this.value = null;
      this.$emit("cancel");
    },
  },
  created() {
    this.value = null;
  },
};
</script>
