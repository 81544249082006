<template>
  <draggable
    handle=".handle"
    tag="transition-group"
    :component-data="{
      tag: 'ul',
      type: 'transition-group',
      name: !drag ? 'flip-list' : null,
    }"
    v-model="itemList"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
    item-key="id"
  >
    <v-list-item
      style="cursor: pointer"
      class="list-item-group"
      v-for="item in itemList"
      :key="item.id"
      :value="item.name"
    >
      <template v-slot:prepend>
        <v-list-item-action start v-if="item.checkbox">
          <v-checkbox-btn
            class="m-0"
            v-model="item.checked"
            @update:model-value="this.$emit('itemChecked', item)"
          ></v-checkbox-btn>
        </v-list-item-action>
      </template>
      <template v-slot:append>
        <v-list-item-action
          end
          :key="action.actionName"
          v-for="action in item.actions"
        >
          <v-btn
            class="d-flex m-0"
            :class="{ handle: action.actionName === 'shuffle' }"
            size="x-small"
            :icon="action.icon"
            color="button-primary"
            variant="plain"
            :title="action.title"
            @click="
              this.$emit('actionClicked', {
                item: item,
                actionName: action.actionName,
              })
            "
          />
        </v-list-item-action>
      </template>

      <v-list-item-title class="p-0">
        <em @click="this.$emit('listItemClicked', item)">{{
          item.text
        }}</em></v-list-item-title
      >
    </v-list-item>
  </draggable>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: "ConfigurableListComponent",
  components: {
    draggable: VueDraggableNext,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    items: {
      handler(newItems) {
        this.itemList = newItems;
      },
      deep: true,
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  data() {
    return {
      itemList: [],
      drag: false,
    };
  },
  created() {
    this.itemList = this.items;
  },
};
</script>
<style>
.ghost {
  opacity: 0.5;
  background: #e9ebec !important;
}
.list-group-item {
  cursor: move;
  padding: 0;
}
</style>
