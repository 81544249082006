<template>
  <v-row class="h-100 m-0">
    <v-col cols="12">
      <div class="d-flex flex-column py-1 px-4">
        <div>
          <v-btn
            to="/home"
            variant="plain"
            prepend-icon="mdi-arrow-left"
            class="p-auto mx-4 my-2 flex-button text-decoration-none"
            width="200"
            rounded
          >
            {{ this.$filters.translate("ipbrary_034") }}
          </v-btn>
        </div>

        <componentList
          class="flex-table"
          :mxHeight="calcHeight"
          :componentTableConfig="tableConfig"
          v-on:componentAdded="updateTable($event, 'added')"
          v-on:componentDeleted="updateTable($event, 'deleted')"
          v-on:componentsDeleted="updateTable($event, 'multipleDeleted')"
          v-on:componentUpdated="updateTable($event, 'componentUpdated')"
        ></componentList>

        <div class="mx-auto">
          <v-btn
            v-if="tableConfig.content.length > 0"
            color="button-secondary"
            rounded
            class="flex-search-button"
            :disabled="disableSearch"
            @click="componentSearch()"
            >{{ this.$filters.translate("ipbrary_039") }}</v-btn
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ComponentList from "./components/ComponentList.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ComponentListView",
  components: {
    componentList: ComponentList,
  },

  computed: {
    /**
     *
     */
    ...mapGetters({
      llmSearchQuery: "stateLlmSearchQuery",
      componentListResponse: "stateComponentListResponse",
    }),
    /**
     *
     */
    calcHeight() {
      const height =
        window.innerHeight -
        // navbar
        64 -
        // p-4
        1.5 * 16 * 3 -
        // buttons
        110;
      return height;
    },
  },
  watch: {
    tableConfig: {
      handler(newConfig) {
        this.disableSearch = newConfig.content.length === 0;
      },
      deep: true,
    },
  },
  data() {
    return {
      disableSearch: false,

      tableConfig: {
        showDeleteAll: true,
        clickRow: false,
        actions: [
          {
            icon: "mdi-delete-outline",
            action: "removeComponent",
            title: this.$filters.translate("ipbrary_043"),
          },
          {
            icon: "mdi-pencil-outline",
            action: "editComponent",
            title: this.$filters.translate("ipbrary_044"),
          },
        ],
        content: [],
        header: [
          {
            name: "",
            descr: "multiselectDeletion",
            multiselect: true,
          },
          {
            name: this.$filters.translate("ipbrary_038"),
            descr: "name",
            sortBy: "name",
            sort: "asc",
            type: "string",
            activeSort: true,
            editable: true,
          },
          {
            name: this.$filters.translate("ipbrary_033"),
            descr: "action",
            center: true,
            width: 150,
          },
        ],
        controlButtons: [
          {
            descr: "newComponent",
            label: this.$filters.translate("ipbrary_022"),
            icon: "mdi-plus",
            title: this.$filters.translate("ipbrary_023"),
            enabled: true,
            hideButton: false,
          },
        ],
      },
      actions: [
        {
          actionName: "editComponent",
          actionEnabled: true,
        },
        {
          actionName: "removeComponent",
          actionEnabled: true,
        },
      ],
    };
  },

  methods: {
    ...mapActions(["llmSearch"]),
    /**
     *
     * @param {*} comp
     * @param {*} action
     */
    updateTable(comp, action) {
      switch (action) {
        case "added":
          // add to the list
          //generate id
          comp.id =
            this.tableConfig.content.length == 0
              ? 0
              : Math.max(
                  ...this.tableConfig.content.map((element) => {
                    return element.id;
                  })
                ) + 1;

          comp.actions = this.actions;

          this.tableConfig.content.push(comp);
          break;
        case "deleted":
          // remove from list
          this.tableConfig.content = this.tableConfig.content.filter(
            (element) => element.id != comp.id
          );
          break;
        case "multipleDeleted":
          // delete many, take the diff
          this.tableConfig.content = this.tableConfig.content.filter(
            (objectOne) =>
              !comp.some((objectTwo) => objectOne.id === objectTwo.id)
          );

          break;
        case "componentUpdated":
          this.tableConfig.content = this.tableConfig.content.map((element) => {
            if (element.id == comp.id) {
              return { ...element, name: comp.name };
            }
            return element;
          });
          break;
      }

      // update state
      this.$store.commit(
        "setComponentList",
        this.tableConfig.content.map((element) => {
          return { id: element.id, name: element.name };
        })
      );
    },
    /**
     *
     */
    componentSearch() {
      // reset the panel data, new search
      this.$store.commit("setPanelData", { panels: [], searchContext: null });
      this.$router.push({
        name: "ComponentSearch",
      });
    },
    /**
     *
     */
    disable(content) {
      return content.find((comp) => !comp.name || !comp.name.trim());
    },
  },

  created() {
    this.tableConfig.tableName = this.$filters
      .translate("ipbrary_052")
      .replace("_placeholder", this.llmSearchQuery);
    this.tableConfig.content = this.componentListResponse.components_list.map(
      (element) => {
        element.actions = this.actions;
        return element;
      }
    );
  },
};
</script>
<style>
.flex-button {
  flex: 1 0 15%;
}
.flex-table1 {
  flex: 1 0 80%;
  position: relative;
  top: -50px !important;
  min-height: 500px !important;
}
.flex-search-button {
  flex-basis: 100%;
  max-width: 200px !important;
}
</style>
