<template>
  <v-container>
    <v-row class="justify-center">
      <p
        class="text-secondary"
        v-html="
          this.$filters.formatLink(
            'ipbrary_005',
            [
              {
                href: 'https://metisai.it/',
                placeholder: 'Metis',
                openInTab: true,
              },
            ],
            'text-typo-ternary'
          )
        "
      ></p>
    </v-row>

    <v-row class="justify-center">
      <p>
        <router-link :class="this.fontColor" to="/terms_conditions">
          Terms of Use
        </router-link>
        |
        <router-link :class="this.fontColor" to="/privacy_policy">
          Privacy Policy
        </router-link>
      </p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    fontColor: {
      type: String,
      default: "text-primary",
    },
  },
};
</script>
