<template>
  <v-responsive :max-width="width">
    <v-form @submit.prevent="doSearch" autocomplete="off">
      <v-text-field
        type="text"
        :density="density"
        clearable
        hide-details
        :placeholder="this.$filters.translate(placeholder)"
        required
        bg-color="white"
        :color="color"
        class="search-input"
        :class="searchClass"
        :variant="variant"
        v-model="searchQuery"
        @click:prepend-inner="doSearch"
        @keydown.enter.prevent="doSearch()"
      >
        <template v-slot:prepend-inner>
          <v-icon icon="mdi-magnify" :color="color"></v-icon>
        </template>
        <template v-slot:label>
          <span :class="searchClass">{{ searchLabel }}</span>
        </template>
      </v-text-field>
    </v-form>
  </v-responsive>
</template>

<script>
export default {
  name: "SearchInputComponent",
  props: {
    variant: {
      type: String,
      default: "outlined",
    },
    density: {
      type: String,
      default: "default",
    },
    color: {
      type: String,
      default: "input-primary",
    },
    label: {
      type: String,
      default: "ipbrary_048",
    },
    placeholder: {
      type: String,
      default: "ipbrary_050",
    },
    width: {
      type: Number,
      default: 700,
    },
  },
  computed: {
    searchLabel() {
      if (!this.searchQuery) return this.$filters.translate(this.label);

      return this.searchMode === "semantic"
        ? this.$filters.translate("ipbrary_049")
        : this.$filters.translate("ipbrary_053");
    },
    searchClass() {
      if (!this.searchQuery) return "ai-search";
      const styleClass = this.rules.semantic(this.searchQuery)
        ? "ai-search"
        : "keyword-search";
      return styleClass;
    },
  },
  watch: {
    searchQuery(newValue) {
      this.searchMode = newValue
        ? this.rules.semantic(newValue)
          ? "semantic"
          : "keyword"
        : null;
    },
  },
  data() {
    return {
      searchQuery: null,
      searchMode: null,
      rules: {
        semantic: (value) => {
          const pattern = /^\w+(?:-\w+)*(?:\s+\w+(?:-\w+)*){3}/;
          return pattern.test(value);
        },
      },
    };
  },
  methods: {
    doSearch() {
      this.$emit("doSearch", {
        searchQuery: this.searchQuery,
        searchMode: this.searchMode,
      });
    },
  },
};
</script>

<style>
input:focus {
  outline: none;
}

.search-input {
  border: none;
  margin: auto;
  width: 100%;
  padding: 0.5%;
  margin-top: 5px;
}
.keyword-search {
  color: #b2afa8;
}
.ai-search {
  color: #75736e;
}
</style>
