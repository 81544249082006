import axiosWrapper from "../axios/axiosWrapper";

const getters = {
  statePdfBinary: (state) => state.pdfBinary,
};

const getDefaultPdfState = () => {
  return {
    pdfs: null,
    pdfBinary: new ArrayBuffer(0),
  };
};

// initial state
const state = getDefaultPdfState();

const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} data
   */
  async getPdf({ commit }, documentId) {
    const response = await axiosWrapper.get(
      `/pdf_binary/`,
      null,
      { document_id: documentId },
      null,
      "arraybuffer"
    );
    const pdfBinaryArray = response.data;
    commit("setPdfBinary", pdfBinaryArray);
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} pdfBinary
   */
  setPdfBinary(state, pdfBinary) {
    state.pdfBinary = pdfBinary;
  },
  /**
   *
   * @param {*} state
   */
  resetPdfState(state) {
    Object.assign(state, getDefaultPdfState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
