<template>
  <v-dialog v-model="dialog" contained location="top" :persistent="persistent">
    <v-card class="w-auto h-auto m-auto rounded-0">
      <template v-slot:title>
        <div class="d-flex flex-row justify-space-between p-1">
          <h5>{{ title }}</h5>
          <v-btn
            v-if="showCloseButton"
            class="my-auto p-auto"
            icon="mdi-close"
            color="primary"
            size="x-small"
            @click="onCloseModal"
          ></v-btn>
        </div>
      </template>

      <v-card-text>
        <p>{{ content }}</p>
      </v-card-text>
      <v-card-actions class="justify-content-center m-2">
        <v-btn
          v-if="isConfirmationModal"
          color="button-secondary"
          variant="plain"
          class="mr-2"
          @click="onCancel"
        >
          {{ cancelButtonLabel }}
        </v-btn>
        <v-btn color="button-primary" variant="flat" @click="onOk">{{
          okButtonLabel
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "info",
    },
    okButtonLabel: {
      type: String,
      default: "OK",
    },
    cancelButtonLabel: {
      type: String,
      default: "Cancel",
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: "color: primary;",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isConfirmationModal() {
      return this.type === "confirmation";
    },
    closeDialog() {
      return !this.dialog;
    },
  },
  methods: {
    onOk() {
      this.$emit("ok");
    },
    onCancel() {
      this.$emit("cancel");
    },

    onCloseModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
