<template>
  <v-row class="bg-primary h-100 m-auto d-flex flex-column">
    <v-col
      cols="12"
      class="m-auto"
      style="height: 100%; justify-content: space-evenly"
    >
      <v-card
        id="privacy"
        class="px-2 d-privacy"
        style="display: grid; text-align: justify"
      >
        <v-card-title class="h4 m-auto" style="text-wrap: wrap !important"
          >Informativa privacy – Servizio “Legenda”
        </v-card-title>
        <v-card-text>
          <div>
            <p class="p-1">
              Ai sensi dell’art. del Regolamento UE n. 2016/679, relativo alla
              protezione delle persone fisiche con riguardo al trattamento dei
              dati personali, nonché alla libera circolazione di tali dati e che
              abroga la direttiva 95/46/CE, (di seguito “GDPR”) questo documento
              informa gli Utenti del Servizio (gli Interessati) che i dati
              personali da questi forniti formeranno oggetto di trattamento nel
              rispetto della normativa sopra richiamata e degli obblighi di
              riservatezza di cui agli accordi con Metis Srl.
            </p>
          </div>

          <div>
            <span class="h5">Titolare del trattamento</span>
            <p class="p-1">
              Il Titolare del trattamento è Metis Srl, con sede in Milano,
              Piazza Sicilia 6, contattabile a mezzo mail a
              <a href="mailto:info@metisai.it">info@metisai.it</a>.
            </p>
          </div>

          <div>
            <span class="h5">Responsabili del trattamento</span>
            <p class="p-1">
              Responsabile del trattamento è Amazon Web Services EMEA SARL, con
              sede in Lussemburgo, 38 Avenue John F Kennedy L-1855,
              relativamente alle attività di conservazione dei documenti
              informatici dell’Utente qualora gli stessi contengano dati
              personali.
            </p>
            <p class="px-1">
              E’ altresì Responsabile del trattamento Stripe Payments Europe,
              Limited, con sede in Irlanda, 3 Dublin Landings, North Wall Quay,
              Dublin 1, Dublino, relativamente alle attività legate alla
              gestione dei pagamenti.
            </p>
            <p class="px-1">
              Ulteriori soggetti potranno essere designati dal Titolare, con
              specifico incarico, quali Responsabili del trattamento, anche per
              le attività di manutenzione, sviluppo, miglioramento e gestione
              delle funzionalità dei prodotti e servizi del Titolare.
            </p>
            <p class="px-1">
              L’interessato potrà contattare in ogni momento il Titolare per
              conoscere l’elenco e i recapiti dei Responsabili del trattamento.
            </p>
          </div>

          <div>
            <span class="h5">Finalità del trattamento e base giuridica</span>
            <ol type="1" class="list-padding">
              <li class="ml-4 p-1">
                <p>
                  I dati personali forniti durante la fase di registrazione al
                  Servizio ed eventuale sottoscrizione del contratto con il
                  Titolare del trattamento – e loro successivi aggiornamenti –
                  sono necessari per l’esecuzione del contratto con
                  l’Interessato e per l’adempimento degli obblighi di legge,
                  anche di natura fiscale, del Titolare del trattamento. La base
                  giuridica del trattamento di tali dati è pertanto quella
                  prevista dall’art. 6, lettera b) (contratto) e lettera c)
                  (obbligo del Titolare) del GDPR.
                </p>
              </li>
              <li class="ml-4 p-1">
                <p>
                  I medesimi dati potranno inoltre essere utilizzati dal
                  Titolare al fine di contattare l’Interessato con offerte
                  commerciali dirette relative ai servizi da questi utilizzati o
                  ad essi analoghi. In tal caso la base giuridica del
                  trattamento è quella prevista dall’art. 6, lettera f)
                  (legittimo interesse) del GDPR. L’Interessato potrà sempre
                  opporsi a questo trattamento con richiesta scritta al
                  Titolare.
                </p>
              </li>
              <li class="ml-4 p-1">
                <p>
                  I medesimi dati potranno infine essere utilizzati dal Titolare
                  per (i) offerte commerciali indirette e (ii) profilazione
                  anche mediante l’elaborazione di studi, ricerche statistiche e
                  di mercato. Tali trattamenti saranno effettuati solo previo
                  espresso e specifico consenso dell’Interessato e la base
                  giuridica del trattamento è pertanto quella prevista dall’art.
                  6, lettera a) del GDPR. L’Interessato potrà sempre revocare
                  tali consensi con richiesta scritta al Titolare.
                </p>
              </li>
              <li class="ml-4 p-1">
                <p>
                  I dati personali eventualmente forniti durante l’utilizzo del
                  Servizio sono necessari per l’esecuzione del contratto con
                  l’Interessato, in particolare per l’indicizzazione dei
                  documenti e la risposta alle Query dell’Utente. Tale
                  trattamento ha per tanto come base giuridica quella prevista
                  dall’art. 6, lettera b) (contratto) del GDPR.
                </p>
              </li>

              <li class="ml-4 p-1">
                <p>
                  I medesimi dati potranno altresì essere utilizzati per il c.d.
                  addestramento degli algoritmi di intelligenza artificiale
                  impiegati dal Titolare, in tal caso previo espresso e
                  specifico consenso dell’Interessato e la base giuridica del
                  trattamento è pertanto quella prevista dall’art. 6, lettera a)
                  del GDPR. L’Interessato potrà sempre revocare tali consensi
                  con richiesta scritta al Titolare.
                </p>
              </li>
            </ol>
          </div>

          <div>
            <span class="h5">Modalità di trattamento</span>
            <p class="p-1">
              Il trattamento sarà svolto in forma automatizzata e manuale, nel
              rispetto di quanto previsto dal GDPR in materia di misure di
              sicurezza, ad opera di soggetti appositamente incaricati.
            </p>
          </div>

          <div>
            <span class="h5">Conservazione</span>
            <p class="p-1">
              I dati personali raccolti saranno conservati per il periodo di
              tempo strettamente necessario per il conseguimento delle finalità,
              indicate nella presente informativa, per le quali sono raccolti e
              trattati e salvo l’esercizio dei diritti dell’Interessato.
            </p>
            <p class="px-1">
              Con particolare riguardo ai dati personali eventualmente contenuti
              nei documenti informatici caricati durante l’utilizzo del
              Servizio, gli stessi vengono cancellati il trentesimo giorno
              successivo al momento in cui l’Utente li rimuove dalla
              piattaforma, al fine di consentire il recupero del documento ove
              richiesto dall’Utente. E’ in ogni caso diritto dell’Utente
              chiedere al titolare l’immediata cancellazione.
            </p>
            <p class="px-1">
              Tutti gli altri dati personali vengono conservati per tutta la
              durata del contratto e per il tempo successivo necessario a
              tutelare i diritti nascenti dal contratto e ad adempiere gli
              obblighi di legge in capo al Titolare.
            </p>
            <p class="px-1">
              È incluso nel tempo di conservazione di cui al paragrafo che
              precede il periodo di un anno dalla fine del rapporto contrattuale
              con l’interessato al fine di garantire la corretta applicazione di
              meccanismi promozionali eventualmente concessi, limitatamente ai
              dati a ciò necessari, tra i quali l’indirizzo email
              dell’interessato.
            </p>
            <p class="px-1">
              Previa manifestazione di espresso e specifico consenso, i dati
              personali potranno altresì essere conservati, a prescindere dalle
              ulteriori necessità, per due anni dal termine del rapporto
              contrattuale, rinnovabili con nuova manifestazione di consenso,
              per fini di marketing diretto.
            </p>
          </div>

          <div>
            <span class="h5">Ambito territoriale</span>
            <p class="p-1">
              I dati raccolti saranno trasferiti verso Paesi situati all’esterno
              dell’Unione Europea, solo ove la sicurezza del trasferimento e del
              successivo trattamento siano pienamente garantite a livelli
              paragonabili a quelli fissati dal GDPR, in particolare ricorrendo
              ad accordi che incorporino le Clausole Contrattuali Standard
              (SCCs) adottate con la Decisione di esecuzione (UE) 2021/914 della
              Commissione del 4 giugno 2021 e successive modifiche ed
              aggiornamenti, o ad ogni altra modalità o strumento consentiti
              dalla normativa in materia al fine di garantire la menzionata
              sicurezza.
            </p>
          </div>

          <div>
            <span class="h5">Diritti dell’interessato</span>
            <ol type="a" class="list-padding">
              <span>
                L’interessato, in ogni momento, con riferimento ai dati
                personali conferiti, ha diritto di:
              </span>

              <li class="ml-4 p-1">accedervi e chiederne copia;</li>
              <li class="ml-4 p-1">
                chiederne la rettifica e l’aggiornamento;
              </li>
              <li class="ml-4 p-1">
                chiederne la cancellazione o la limitazione del trattamento, ove
                possibile;
              </li>
              <li class="ml-4 p-1">chiederne la portabilità;</li>
              <li class="ml-4 p-1">
                opporsi al trattamento e revocare i consensi prestati;
              </li>
              <li class="ml-4 p-1">
                chiedere maggiori informazioni sugli aspetti di questa
                informativa;
              </li>
              <li class="ml-4 p-1">
                proporre un reclamo al Garante per la Privacy.
              </li>
              <span
                >Tali diritti possono essere esercitati con richiesta scritta al
                Titolare (salvo quello di cui alla lettera g).</span
              >
            </ol>
          </div>

          <div>
            <span class="h5">Cookies</span>
            <p class="p-1">
              Il sito web del Servizio utilizza c.d. cookies (strumenti tecnici
              consistenti in stringhe di testo memorizzate sul dispositivo
              dell’Utente) che possono contenere dati personali. Tali cookies
              possono avere natura tecnica – e il loro utilizzo è quindi
              necessario per la navigazione del sito a prescindere dal consenso
              dell’interessato – oppure analitica, o, ancora, di profilazione,
              nei quali ultimi due casi la creazione e l’utilizzo avvengono solo
              previo consenso dell’interessato. Si rinvia per maggiori dettagli
              in merito alle finalità, alle basi giuridiche, ai tempi di
              conservazione e ai diritti dell’interessato in merito ai cookies,
              alla cookie policy presente sul sito.
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PrivacyView",
};
</script>
