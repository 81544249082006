<template>
  <v-dialog
    hide-overlay
    v-model="showDialog"
    :scrollable="scrollable"
    location="top"
    :persistent="persistent"
    :contained="contained"
  >
    <v-container class="modal-style" :class="modalClass">
      <header class="modal-header">
        <slot name="header"> This is the default title! </slot>
        <div class="d-flex">
          <v-btn
            v-if="showMinimizeBtnHeader"
            class="p-auto text-primary"
            icon="mdi-window-minimize"
            variant="tonal"
            size="x-small"
            @click="minimizeModal"
          ></v-btn>
          <v-btn
            v-if="showCloseBtnHeader"
            class="p-auto text-primary"
            icon="mdi-close"
            variant="tonal"
            size="x-small"
            @click="closeModal"
            :disabled="disableCloseBtnHeader"
          ></v-btn>
        </div>
      </header>

      <section>
        <slot name="body"> This is the default body! </slot>
      </section>

      <footer>
        <slot name="footer"> This is the default footer! </slot>
        <div
          class="d-flex justify-center"
          v-if="showCloseBtn || showConfirmBtn"
        >
          <v-btn
            v-if="showCloseBtn"
            class="mr-2"
            color="button-secondary"
            @click="closeModal"
            variant="plain"
          >
            {{ cancelLabel }}
          </v-btn>
          <v-btn
            v-if="showConfirmBtn"
            class="mr-2"
            variant="flat"
            color="button-primary"
            @click="confirm"
          >
            {{ confirmLabel }}
          </v-btn>
        </div>
      </footer>
    </v-container>
  </v-dialog>
</template>
<script>
export default {
  name: "BaseModal",
  methods: {
    closeModal() {
      this.$emit("close");
    },
    minimizeModal() {
      this.$emit("minimize");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  data() {
    return { showDialog: false };
  },
  props: {
    persistent: {
      type: Boolean,
      default: false,
    },
    contained: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    showCloseBtnHeader: {
      type: Boolean,
      default: true,
    },
    showMinimizeBtnHeader: {
      type: Boolean,
      default: false,
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    showConfirmBtn: {
      type: Boolean,
      default: false,
    },
    confirmLabel: {
      type: String,
      default: "OK",
    },
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
    modalClass: {
      type: String,
      default: "small-modal",
    },
    controlIconClose: {
      type: String,
      default: "mdi-close",
    },
    controlIconMinimize: {
      type: String,
      default: "mdi-window-minimize",
    },
    disableCloseBtnHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.modal-style {
  background: white !important;
  margin: auto;
  padding: 2.5%;
  border-bottom: none !important;
}
.small-modal {
  width: auto;
  min-width: 330px;
  max-width: 400px !important;
}
.big-modal {
  width: 100% !important;
  height: 100%;
  max-width: 95vw !important;
}

.medium-modal {
  width: 100% !important;
  height: 100%;
  max-width: 50vw !important;
}

.modal-header {
  border-bottom: none !important;
}
</style>
