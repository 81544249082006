import store from "../../../../store";

class InspectSearchService {
  /**
   *
   * @param {*} selectedItem
   * @param {*} documentId
   * @param {*} page
   * @param {*} name
   * @param {*} keySentence
   * @param {*} mode
   */
  setCurrentDocument(selectedItem, documentId, page, name, keySentence, mode) {
    selectedItem.document_id = documentId;
    selectedItem.page = page;
    selectedItem.fileName = name;
    selectedItem.keySentence = keySentence;
    selectedItem.searchMode = mode != null ? mode : selectedItem.searchMode;
  }
  /**
   *
   * @param {*} searchResults
   * @param {*} fileName
   * @param {*} keySentencesLoaded
   * @returns
   */
  folderSearchPayload(searchResults, fileName, keySentencesLoaded) {
    // take as many results as are loaded
    let payload = {
      slicedPages: [],
      slicedSentences: [],
    };
    const loadedSentencesByDocument = this.getLoadedResultsByDocument(
      searchResults,
      fileName,
      keySentencesLoaded
    );
    payload.slicedPages = loadedSentencesByDocument["keyPages"];
    payload.slicedSentences = loadedSentencesByDocument["keySentences"];
    return payload;
  }
  /**
   *
   * @param {*} searchResults
   * @param {*} fileName
   * @param {*} keySentencesLoaded
   * @returns
   */
  getLoadedResultsByDocument(searchResults, fileName, keySentencesLoaded) {
    //because all results come ordered from the server,
    //we need to filter them according to the pdf index and their relative position
    //find which indices correspond to this file
    const file_name = fileName;
    let loadedResultsByDocument = { keyPages: [], keySentences: [] };
    const indicesByDocument = searchResults.reduce(function (
      newArray,
      result,
      index
    ) {
      if (result.pdf === file_name) newArray.push(index);
      return newArray;
    },
    []);

    if (indicesByDocument) {
      //for each one of these elements, construct the filtered results
      indicesByDocument.forEach((index) => {
        if (index < keySentencesLoaded) {
          loadedResultsByDocument["keyPages"].push(searchResults[index].page);

          loadedResultsByDocument["keySentences"].push(
            searchResults[index].sentence
          );
        }
      });
    }
    return loadedResultsByDocument;
  }
  /**
   *
   * @param {*} searchResults
   * @returns
   */
  documentSearchPayload(searchResults) {
    // take as many results as are loaded
    let payload = {
      slicedPages: [],
      slicedSentences: [],
    };
    payload.slicedSentences = searchResults
      .map((x) => x.sentence)
      .slice(0, this.sentencesLoaded);
    payload.slicedPages = searchResults
      .map((x) => x.page)
      .slice(0, this.sentencesLoaded);

    return payload;
  }
  /**
   *
   * @param {*} searchResults
   * @param {*} selectedItem
   * @param {*} keySentencesLoaded
   * @param {*} highlightPdfFunc
   * @param {*} createPayloadFunc
   */
  async highlightPdf(
    searchResults,
    selectedItem,
    keySentencesLoaded,
    highlightPdfFunc,
    createPayloadFunc
  ) {
    const payload = createPayloadFunc(
      searchResults,
      selectedItem.fileName,
      keySentencesLoaded
    );

    await this._highlightPdf(payload, selectedItem, highlightPdfFunc);
  }
  /**
   *
   * @param {*} payload
   * @param {*} selectedItem
   * @param {*} highlightPdfFunc
   */
  async _highlightPdf(payload, selectedItem, highlightPdfFunc) {
    // get highlighted document
    let highlightedDocument = await highlightPdfFunc({
      documentId: selectedItem.document_id,
      keyElements: this.highlightRequestPayload(payload, selectedItem),
    });

    // set state
    const pdfBinaryArray = highlightedDocument.data;
    store.commit("setPdfBinary", pdfBinaryArray); //take out
  }
  /**
   *
   * @param {*} slicedPayload
   * @param {*} selectedItem
   * @returns
   */
  highlightRequestPayload(slicedPayload, selectedItem) {
    let payload = [],
      slicedPages = [],
      slicedSentences = [];

    // check the mode, if keyword or semantic
    // if semantic, pass on only the currently selected passage
    if (selectedItem.searchMode === "semantic") {
      slicedPages = [selectedItem.page];
      slicedSentences = [selectedItem.keySentence];
    } else if (selectedItem.searchMode === "keyword") {
      slicedPages = slicedPayload.slicedPages;
      slicedSentences = slicedPayload.slicedSentences;
    }

    // construct the payload array
    payload = slicedSentences.map(function (v, i) {
      return {
        key_sentence: v,
        key_page: slicedPages[i],
      };
    });

    return payload;
  }
  /**
   *
   * @param {*} searchResults
   * @param {*} selectedItem
   * @param {*} keySentencesLoaded
   * @param {*} highlightPdfFunc
   * @param {*} createPayloadFunc
   */
  async loadPdf(
    searchResults,
    selectedItem,
    keySentencesLoaded,
    highlightPdfFunc,
    createPayloadFunc
  ) {
    if (searchResults) {
      this.setCurrentDocument(
        selectedItem,
        searchResults[0].document_id,
        searchResults[0].page, // on init we show the page of the first result!
        searchResults[0].pdf,
        searchResults[0].sentence
      );

      await this.highlightPdf(
        searchResults,
        selectedItem,
        keySentencesLoaded,
        highlightPdfFunc,
        createPayloadFunc
      );
    }
  }
  /**
   *
   * @param {*} absoluteIndex
   * @param {*} searchQueryResults
   * @param {*} selectedItem
   * @param {*} keySentencesLoaded
   * @param {*} highlightPdfFunc
   * @param {*} createPayloadFunc
   */
  async showResultOnDocument(
    absoluteIndex,
    searchQueryResults,
    selectedItem,
    keySentencesLoaded,
    highlightPdfFunc,
    createPayloadFunc
  ) {
    // set document
    const docId = selectedItem.document_id;
    this.setCurrentDocument(
      selectedItem,
      searchQueryResults[absoluteIndex - 1].document_id,
      searchQueryResults[absoluteIndex - 1].page,
      searchQueryResults[absoluteIndex - 1].pdf,
      searchQueryResults[absoluteIndex - 1].sentence
    );

    const newDocument = docId !== selectedItem.document_id;
    if (newDocument || selectedItem.searchMode === "semantic") {
      await this.highlightPdf(
        searchQueryResults,
        selectedItem,
        keySentencesLoaded,
        highlightPdfFunc,
        createPayloadFunc
      );
    }
  }
}

const inspectSearchService = new InspectSearchService();

export default inspectSearchService;
