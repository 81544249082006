<template>
  <v-app-bar color="white" prominent>
    <v-container class="d-flex justify-space-between align-items-center" fluid>
      <router-link to="/home" class="d-flex align-items-center">
        <v-img class="logo" :src="logoImageComputed" :min-width="100"
          style="width: 12vw; height: auto; margin-right: 20px;margin-left: 0;" />
      </router-link>
      <div class="d-flex align-items-center ml-auto">
        <v-btn class="text-none px-4 py-2 mx-2 purple-btn" :title="this.$filters.translate('ipbrary_002')" color="#6D31EDFF"
          :href="feedbackLink" target="_blank">
          <v-icon>mdi-plus-circle-outline</v-icon>
          <span>Create Patent</span>
        </v-btn>
        <v-menu location="top" class="p-0 m-0">
          <template v-slot:activator="{ props }">
            <v-btn class="text-none p-0 mx-2 account-btn" stacked v-bind="props"
              :title="this.$filters.translate('ipbrary_001')" color="surface">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item nav style="text-decoration: none; color: inherit; cursor: pointer">
              <v-list-item-title @click="logout">{{
                this.$filters.translate("ipbrary_004")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import logoImg from "../.././assets/images/ipbrary_logo-positive_crop.png";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavBar",
  computed: {
    ...mapGetters({
      user: "stateUser",
      searchable: "stateSearchable",
    }),
    feedbackLink() {
      return "https://airtable.com/appowoXZgOIoNTtjc/shrzPRmgWilF1aWop";
    },
    logoImageComputed() {
      return this.logoImage;
    },
  },
  data() {
    return {
      logoImage: logoImg,
      dragover: false,
      searchQuery: null,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    ...mapActions(["logOut"]),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    async logout() {
      await this.logOut(this.user.username);
      this.$store.commit("resetState");
      this.$router.push("/login");
    },
  },
};
</script>

<style>

.v-toolbar__content{
  height: 100px !important;
}
.account-btn {
  background: #171A1FFF !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 1000px !important;
  padding: 0;
  min-width: 36px !important;
  min-height: 36px !important;
}

.v-toolbar {
  box-shadow: none !important;
}

.logo {
  margin-left: 20px;
}

.purple-btn {
  background-color: #6D31EDFF !important;
  color: white !important;
  display: flex;
  align-items: center;
  max-height: 40px !important;
  border-radius: 20px !important;
}

.purple-btn .v-icon {
  margin-right: 8px;
}
</style>
