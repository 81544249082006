<template>
  <!-- used `style="height: 100vh;"` because without it in the Firefox 89 and Chrome 91 (June 2021) the `vue-pdf-app` is not rendering on the page, just empty space without any errors (since `vue-pdf-app` does not have height and it is the top tag in the generated markup ) -->
  <!-- or you can just wrap `vue-pdf-app` in <div> tag and set height for it via CSS (like in `Script tag (unpkg)` example below) -->
  <v-sheet
    style="height: 100%"
    elevation="4"
    rounded="lg"
    border="lg opacity-48"
    @mouseleave.prevent="resetPage($event)"
  >
    <vuePdf
      v-if="binaryFile.byteLength > 0"
      :pdf="binaryFile"
      :page-number="page"
      :page-scale="pageScale"
      @pages-rendered="setPdfViewer($event)"
    />
  </v-sheet>
</template>

<script>
import VuePdfApp from "vue3-pdf-app";
// import this to use default icons for buttons
import "vue3-pdf-app/dist/icons/main.css";

export default {
  name: "PDFJSViewer",

  components: {
    vuePdf: VuePdfApp,
  },

  props: {
    binaryFile: {
      type: ArrayBuffer,
      default() {
        return new ArrayBuffer(0);
      },
    },
    page: {
      type: Number,
      default: 1,
    },
    pageScale: {
      type: String,
      default: "auto",
    },
  },

  data() {
    return {
      pdfViewerClone: null,
    };
  },
  watch: {
    page: {
      handler(newValue) {
        if (this.pdfViewerClone && newValue != 0) {
          this.pdfViewerClone.currentPageNumber = parseInt(newValue);
        }
      },
      immediate: true,
    },
    pageScale: {
      handler(newValue) {
        if (this.pdfViewerClone) {
          this.pdfViewerClone.currentScaleValue = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    setPdfViewer(event) {
      if (this.page || this.pageScale) {
        event.pdfViewer.currentPageNumber = parseInt(this.page);
        event.pdfViewer.currentScaleValue = this.pageScale;
        this.pdfViewerClone = event.pdfViewer;
      }
    },
    resetPage() {
      //emit the change and mutate prop from parent!
      if (this.pdfViewerClone) {
        this.$emit("pageChanged", this.pdfViewerClone.currentPageNumber);
      }
    },
  },
};
</script>
<style>
#mainContainer > div.toolbar {
  z-index: 800 !important;
}
</style>
