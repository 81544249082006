<template>
  <div>
    <div class="d-flex flex-column p-1">
      <span class="h2">{{ this.$filters.translate("ipbrary_010") }}</span>
      <span
        class="d-inline-flex"
        style="font-size: large"
        v-html="formattedText"
      >
      </span>
      <div style="display: contents !important">
        <miniBreadcrumbs2
          class="font-italic font-weight-medium"
          :disableLinks="true"
          :customColor="'black'"
          :parents="currentSearchPath"
        ></miniBreadcrumbs2>
      </div>
    </div>
    <div>
      <h4
        v-if="
          !loadingQuery &&
          keySentencesLoaded == 0 &&
          searchQuery &&
          searchQuery.trim().length > 0
        "
        class="text-danger mt-2"
      >
        {{ this.$filters.translate("ipbrary_009") }}
      </h4>
      <h4 v-if="loadingQuery" class="mt-2">
        {{ this.$filters.translate("ipbrary_008") }}
      </h4>
    </div>

    <div class="pt-3 wrapper-height">
      <div v-for="i in this.keySentencesLoaded" :key="i">
        <v-card
          class="card-style text-typo-primary card-pointer"
          :class="{
            highlight: activeIndex === i,
            'elevation-10': activeIndex === i,
          }"
        >
          <v-card-title
            class="d-flex justify-space-between align-items-flex-start"
            :class="[textColor(i), bgColor(i), 'p-3']"
            style="cursor: pointer !important"
          >
            <!--text header -->
            <div
              style="
                display: block;
                align-items: center;
                cursor: pointer !important;
              "
            >
              <span class="h6 header-title-style" @click="resultClicked(i)">
                {{ searchResults.items[i - 1].pdf }} - page
                {{ searchResults.items[i - 1].page }}
              </span>

              <div>
                <miniBreadcrumbs
                  style="font-size: small; font-style: italic"
                  :disableLinks="true"
                  :parents="searchResults.items[i - 1].folder_hierarchy"
                ></miniBreadcrumbs>
              </div>
            </div>

            <!--buttons-->
            <div class="h6">
              <v-btn
                flat
                size="xs"
                class="clipboard-button"
                v-on:click="copyToClipboard(i)"
                v-on:mouseleave="changeTooltip"
              >
                <v-icon
                  icon="mdi-content-copy"
                  color="white"
                  :info="this.$filters.translate('ipbrary_013')"
                ></v-icon>
                <v-tooltip activator="parent" location="top">
                  {{ clipboardTooltipText }}</v-tooltip
                >
              </v-btn>

              <span v-on:click="resultClicked(i)" class="mx-2"
                >{{ i }} / {{ searchResults.items.length }}</span
              >

              <v-btn
                flat
                size="xs"
                class="rating-button"
                v-on:click="resultRated(i - 1, 'good')"
              >
                <v-icon
                  :style="[
                    searchResults.items[i - 1].rating !== 'good'
                      ? 'color: darkolivegreen'
                      : 'color: white',
                  ]"
                  icon="mdi-thumb-up"
                ></v-icon>
              </v-btn>
              <v-btn
                flat
                size="xs"
                class="rating-button ml-2"
                v-on:click="resultRated(i - 1, 'bad')"
              >
                <v-icon
                  :style="[
                    searchResults.items[i - 1].rating !== 'bad'
                      ? 'color: darkolivegreen'
                      : 'color: white',
                  ]"
                  icon="mdi-thumb-down"
                ></v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <!-- eslint-disable  vue/no-v-text-v-html-on-component -->
          <v-card-text
            class="p-3"
            style="cursor: pointer !important"
            @click="resultClicked(i)"
            v-html="searchResults.items[i - 1].sentence"
          >
          </v-card-text>
          <!--eslint-enable-->
        </v-card>
      </div>
      <div v-if="moreToLoad">
        <v-btn
          variant="outlined"
          color="button-contrast"
          @click="loadResults()"
          >{{ this.$filters.translate("ipbrary_011") }}</v-btn
        >
      </div>
      <div class="p-2 text-typo-primary" v-if="showHint">
        <i>{{ this.$filters.translate("ipbrary_012") }}</i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MiniBreadcrumbsComponent from "../breadcrumbs/MiniBreadcrumbsComponent.vue";

export default {
  name: "SearchResults",
  components: {
    miniBreadcrumbs: MiniBreadcrumbsComponent,
    miniBreadcrumbs2: MiniBreadcrumbsComponent,
  },
  props: {
    searchResults: null,
    searchQuery: null,
    loadingQuery: null,
    currentSearchPath: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function. The function receives the raw
      // props received by the component as the argument.
      default() {
        return [];
      },
    },
  },
  computed: {
    keySentencesLoaded: {
      get() {
        return this.keySentencesCount;
      },
      set(value) {
        this.keySentencesCount = value;
      },
    },
    formattedText() {
      const htmlText =
        " <strong class='font-italic' style='display: contents'>" +
        this.searchQuery +
        " </strong>";

      const text = this.$filters
        .translate("ipbrary_021")
        .replace("_placeholder", htmlText);

      return text;
    },

    moreToLoad() {
      return (
        this.keySentences &&
        this.keySentencesCount < this.keySentences.length &&
        this.keySentences.length > this.loadStep
      );
    },
    showHint() {
      return !this.moreToLoad && this.keySentences.length > 0;
    },

    clipboardColor() {
      return this.copied ? "secondary-lighten-1" : "surface-lighten-1";
    },
  },

  watch: {
    searchResults(newValue) {
      if (newValue) {
        this.rankResults();
        this.setKeySentencesCount();
        this.activeIndex = 1;
      } else {
        this.keySentences = [];
        this.setKeySentencesCount();
      }
    },
  },

  data() {
    return {
      keyMatchColors: [],
      keySentences: [],
      keySentencesCount: 0,
      loadStep: 10,
      copied: false,
      clipboardTooltipText: this.$filters.translate("ipbrary_013"),
      searchResultsGui: null,
      activeIndex: 1,
    };
  },

  methods: {
    ...mapActions(["submitRating"]),

    rankResults() {
      try {
        // select sentences to display
        this.keySentences = [];
        this.keyMatchColors = [];
        for (var i = 0; i < this.searchResults.items.length; i++) {
          let score_i = this.searchResults.items[i].distance;
          if (score_i >= 0 || i >= 0) {
            // add sentence
            this.keySentences.push(this.searchResults.items[i].sentence);
            // compute color
            var color = "";
            if (score_i < 0.1) {
              color = "search-low";
            } else if (score_i < 0.7) {
              color = "search-mid";
            } else {
              color = "search-high";
            }
            this.keyMatchColors.push(color);
          }
        }
      } catch (error) {
        // error in displaying search results
        console.log("Error in:", error);
      }
    },
    /**
     *
     * @param {*} absoluteIndex
     */
    resultClicked(absoluteIndex) {
      this.activeIndex = absoluteIndex;
      this.$emit("resultClicked", absoluteIndex);
    },
    /**
     *
     * @param {*} index
     */
    async resultRated(index, rate) {
      const toggleRate =
        this.searchResults.items[index].rating == rate ? null : rate;

      await this.submitRating({
        search_result_id: this.searchResults.items[index].search_result_id,
        rating: toggleRate,
      });

      this.$emit("resultRated", { rate: toggleRate, index: index });
    },

    lightFont() {
      return "text-surface";
    },

    darkFont() {
      return "text-dark";
    },
    textColor(index) {
      const color =
        this.keyMatchColors[index - 1] == "warning"
          ? this.darkFont()
          : this.lightFont();
      return color;
    },
    bgColor(index) {
      return "bg-" + this.keyMatchColors[index - 1];
    },
    /**
     *
     */
    loadResults() {
      if (this.keySentencesCount > this.keySentences.length) return;
      if (this.keySentences.length - this.keySentencesCount >= this.loadStep) {
        this.keySentencesLoaded = this.keySentencesCount + this.loadStep;
      } else {
        this.keySentencesLoaded =
          this.keySentencesCount +
          (this.keySentences.length - this.keySentencesCount);
      }
      this.$emit("keySentencesLoaded", this.keySentencesLoaded);
    },
    /**
     *
     */
    setKeySentencesCount() {
      if (this.keySentences.length <= this.loadStep) {
        this.keySentencesLoaded = this.keySentences.length;
      } else {
        this.keySentencesLoaded = this.loadStep;
      }
    },
    /**
     *
     * @param {*} i
     */
    async copyToClipboard(i) {
      const boldRegex = new RegExp("(<b>)|(</b>)", "g");
      const textToCopy = this.searchResults.items[i - 1].sentence.replace(
        boldRegex,
        ""
      );

      await navigator.clipboard.writeText(textToCopy);

      this.clipboardTooltipText = this.$filters.translate("ipbrary_018");
    },
    /**
     *
     */
    changeTooltip() {
      this.clipboardTooltipText = this.$filters.translate("ipbrary_013");
    },
  },
  created() {
    this.rankResults();
    if (this.keySentences.length > 0) {
      this.setKeySentencesCount();
    }
  },
};
</script>

<style>
@media (max-height: 730px) {
  .wrapper-height {
    overflow-y: auto;
    height: 64vh;
  }
}

@media (min-height: 731px) {
  .wrapper-height {
    overflow-y: auto;
    height: 77vh;
  }
}

.card-style {
  margin: 1.3rem auto;
  word-wrap: break-word;
  cursor: pointer !important;
  width: 95%;
}

.header-title-style {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  flex-wrap: wrap;
  white-space: break-spaces;
}

.clipboard-button {
  background-color: transparent !important;
  margin-left: auto;
}

.rating-button {
  background-color: transparent !important;
  margin-left: 2%;
}
.highlight {
  border: yellow !important;
  border-style: solid !important;
  border-width: thick !important;
  width: 100% !important;
}
</style>
