<template>
  <v-row class="h-100 m-0">
    <v-col cols="12">
      <div class="d-flex flex-column py-1 px-4">
        <div>
          <v-btn
            to="/components"
            variant="plain"
            prepend-icon="mdi-arrow-left"
            class="primary p-auto mt-2 ml-3 text-decoration-none"
            width="250"
            rounded
          >
            {{ this.$filters.translate("ipbrary_040") }}
          </v-btn>
        </div>

        <expansionPanels
          :panelData="panelData"
          v-model="panelData"
          @panelChecked="updateResults($event)"
          @deleteSelected="deleteSelectedResults($event)"
          @removeComponent="removePanel($event)"
          @searchComponent="doSearch($event)"
          @editComponent="enableEdit($event)"
          @updateTitle="updateTitle($event)"
          @resultItemClicked="openSearchResults($event)"
          @resultActionClicked="executeResultAction($event)"
        ></expansionPanels>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ExpansionPanelComponent from "../../../../components/ui/ExpansionPanelComponent.vue";

export default {
  name: "ComponentSearchView",
  components: {
    expansionPanels: ExpansionPanelComponent,
  },
  computed: {
    ...mapGetters({
      llmSearchQuery: "stateLlmSearchQuery",
      componentListResponse: "stateComponentListResponse",
      currentFolderId: "stateCurrentFolderId",
      searchResults: "stateSearchResults",
      statePanelData: "statePanelData",
    }),
  },
  data() {
    return {
      panelData: { panels: [], searchContext: null },
      rules: {
        semantic: (value) => {
          const pattern = /^\w+(?:-\w+)*(?:\s+\w+(?:-\w+)*){3}/;
          return pattern.test(value);
        },
      },
      searchResultActions: [
        {
          actionName: "removeResult",
          icon: "mdi-delete-outline",
          title: this.$filters.translate("ipbrary_045"),
        },
        {
          actionName: "shuffle",
          icon: "mdi-menu",
          title: this.$filters.translate("ipbrary_046"),
        },
      ],
      panelActions: [
        {
          actionName: "editComponent",
          title: this.$filters.translate("ipbrary_044"),
          icon: "mdi-pencil-outline",
          editing: false,
          editable: false,
        },
        {
          actionName: "removeComponent",
          title: this.$filters.translate("ipbrary_043"),
          icon: "mdi-delete-outline",
          editing: false,
          editable: false,
        },
        {
          actionName: "searchComponent",
          title: this.$filters.translate("ipbrary_047"),
          icon: "mdi-magnify",
          editing: false,
          editable: true,
          searchInput: null,
        },
      ],
    };
  },
  methods: {
    /**
     *
     */
    ...mapActions(["llmSearch", "componentSearch", "search"]),
    /**
     *
     * @param {*} panel
     */
    updateResults(panel) {
      const tmp = _.cloneDeep(panel.items);
      tmp.forEach((item) => (item.checked = panel.checked));
      panel.items = tmp;
    },
    /**
     *
     * @param {*} panelToRemove
     */
    removePanel(panelToRemove) {
      // remove panel from panel array
      this.panelData.panels = this.panelData.panels.filter(
        (panel) => panel.id !== panelToRemove.id
      );
      // remove also from the state
      const stateCompList = this.componentListResponse.components_list.filter(
        (panel) => panel.id !== panelToRemove.id
      );
      this.$store.commit("setComponentList", stateCompList);
    },
    /**
     *
     */
    deleteSelectedResults(selectedPanels) {
      //iterate through all the search results from panelData and remove all the data
      selectedPanels.forEach((selectedPanel) => {
        this.deleteSearchResultsByPatentId(selectedPanel);
      });
    },
    /**
     *
     * @param {*} selectedPanel
     */
    deleteSearchResultsByPatentId(selectedPanel) {
      //get the corresponding panel of panelData array
      const panelToUpdate = this.panelData.panels.find(
        (panel) => panel.id == selectedPanel.id
      );
      if (panelToUpdate) {
        // remove selected items from original top patent array
        panelToUpdate.items = panelToUpdate.items.filter(
          (e) => !selectedPanel.items.find((a) => e.id == a.id)
        );

        // remove selected items also from the results array
        // remove by patendId and searchQueryId
        const deleteConditions = [
          ...selectedPanel.items.map((item) => {
            return {
              queryId: item.searchQueryId,
              patentId: item.patentId,
            };
          }),
        ];
        // update search results
        panelToUpdate.results.filter((result) => {
          deleteConditions.forEach((condition) => {
            if (result.searchQueryId === condition.queryId) {
              //filter out all elements with this patentId
              result.items = result.items.filter(
                (item) => item.document_id !== condition.patentId
              );
            }
          });
        });

        panelToUpdate.checked = false;
      }
    },
    /**
     *
     * @param {*} clickedResult
     */
    openSearchResults(clickedResult) {
      // get search query id from panel data
      const panel = this.panelData.panels.find(
        (panel) => panel.id == clickedResult.panel.id
      );
      const results = panel.results.find(
        (searchResults) =>
          searchResults.searchQueryId === clickedResult.result.searchQueryId
      );

      // push to state, no need to fetch the search results again
      this.$store.commit("setQuerySearchResults", results);

      this.$router.push({
        name: "SearchQueryResults",
        params: {
          search_query_id: clickedResult.result.searchQueryId,
        },
        state: {
          searchQuery: results.searchQuery.includes("application_prompt")
            ? panel.name
            : results.searchQuery,
          document_id: null,
        },
      });
    },
    /**
     *
     * @param {*} results
     */
    createTopPatents(searchResultObject) {
      // sort the results to show the first 10 patents
      //get the distinct patents
      const distinctPatents = searchResultObject.items
        .reduce((uniquePatents, patent) => {
          //current document_id
          const document_id = patent.document_id;
          // find it in the unique patents array
          const isDuplicate = uniquePatents.find(
            (patent) => patent.document_id === document_id
          );
          // if found, return the array as is
          if (isDuplicate) return uniquePatents;
          //push and return
          uniquePatents.push(patent);
          return uniquePatents;
        }, [])
        .slice(0, 10);

      // for these distinct patents, build the descriptions
      let descriptions = [];
      distinctPatents.forEach((distinctPatent, index) => {
        const obj = {
          text: distinctPatent.pdf.concat(": ", distinctPatent.sentence),
          id: index, // the actual index in search results arrays
          patentId: distinctPatent.document_id,
          searchQueryId: searchResultObject.searchQueryId,
          actions: _.cloneDeep(this.searchResultActions),
          checkbox: true,
          checked: false,
        };
        descriptions.push(obj);
      });
      return descriptions;
    },
    /**
     *
     * @param {*} resultActionObject
     */
    executeResultAction(resultActionObject) {
      const resultId = resultActionObject.actionObject.item.id;
      const panel = { ...resultActionObject.panel };
      switch (resultActionObject.actionObject.actionName) {
        case "removeResult":
          panel.items = resultActionObject.panel.items.filter(
            (item) => item.id === resultId
          );
          // remove this patent result(s) from the panel
          this.deleteSearchResultsByPatentId(panel);

          break;
      }
    },
    async doSearch(searchQueryObject) {
      if (
        searchQueryObject.action.searchInput &&
        searchQueryObject.action.searchInput.trim()
      ) {
        await this.search({
          parentFolderId: this.currentFolderId,
          key: searchQueryObject.action.searchInput,
        });
        const searchResults = _.cloneDeep(this.searchResults);

        // set search type
        this.setSearchMode(searchResults, searchQueryObject.action.searchInput);

        // the search results stored on state should be appended to the search results
        searchQueryObject.panel.results.push({
          searchQueryId: searchResults.search_query_id,
          searchQuery: searchResults.search_query,
          items: searchResults.items,
        });

        // check if search results were found and construct the new patent list for the panel view
        if (searchResults.items.length > 0) {
          // for the panel view
          const newItems = searchResults.items.map((searchResult, index) => {
            return {
              text: searchResult.pdf.concat(": ", searchResult.sentence),
              patentId: searchResult.document_id,
              id: searchQueryObject.panel.items.length + index,
              searchQueryId: searchResults.search_query_id,
              actions: _.cloneDeep(this.searchResultActions),
              checkbox: true,
              checked: false,
            };
          });

          searchQueryObject.panel.items.push(...newItems);
        }
      }
    },
    /**
     *
     * @param {*} searchResults
     * @param {*} input
     */
    setSearchMode(searchResults, input) {
      const searchMode = this.rules.semantic(input) ? "semantic" : "keyword";
      searchResults.items.forEach((searchResult) => {
        searchResult.search_type = searchMode;
      });
    },
    /**
     *
     * @param {*} panel
     */
    enableEdit(panel) {
      panel.editing = true;
    },
    /**
     *
     * @param {*} panel
     */
    updateTitle(panel) {
      panel.editing = false;
      const componentList = this.componentListResponse.components_list.find(
        (comp) => comp.id === panel.id
      );
      componentList.name = panel.name;
      //this.$store.commit("setPanel", this.panelData);
      this.$store.commit(
        "setComponentList",
        this.componentListResponse.components_list
      );
    },
  },

  /**
   *
   */
  created() {
    if (this.statePanelData.panels.length == 0) {
      this.panelData.panels = _.cloneDeep(
        this.componentListResponse.components_list
      );
      // set up also the progress color on first time loading
      this.panelData.panels.forEach((panel) => {
        panel.progressColor = "primary";
        panel.loading = true;
      });
      this.panelData.searchContext = this.llmSearchQuery;
    } else {
      this.panelData = _.cloneDeep(this.statePanelData);
    }
  },
  /**
   *
   */
  async mounted() {
    // fetch data only if we are entering here for the first time
    if (this.statePanelData.panels.length == 0) {
      for (let i = 0; i < this.panelData.panels.length; i++) {
        try {
          const results = await this.componentSearch({
            applicationPrompt: this.llmSearchQuery,
            component: this.panelData.panels[i].name,
          });

          // remove the parent home from the parents array for all elements
          // (the first element in breadcrumbs should be the actual parent folder of the document,
          // otherwise the divider will be shown before the folder name)
          results.items.forEach((result) => {
            result.folder_hierarchy = result.folder_hierarchy.filter(
              (parent) => parent.folder_name !== "home"
            );
          });
          // push an empty object for those arrays that are empty (so that the icon will be still shown )
          results.items.forEach((searchResult) => {
            if (searchResult.folder_hierarchy.length == 0) {
              searchResult.folder_hierarchy.push({ folder_name: null, folder_id: null });
            }
          });
          this.panelData.panels[i].loading = false;
          this.setSearchMode(results, results.search_query);
          this.panelData.panels[i].results = [];
          this.panelData.panels[i].results.push({
            searchQueryId: results.search_query_id,
            searchQuery: results.search_query,
            items: results.items,
          });

          // when we create the data, show first ten patents from the standard component search
          this.panelData.panels[i].items = this.createTopPatents(
            this.panelData.panels[i].results[0]
          );
        } catch (error) {
          this.panelData.panels[i].progressColor = "red";
          this.panelData.panels[i].loading = false;
        }

        this.panelData.panels[i].panelActions = _.cloneDeep(this.panelActions);
        this.panelData.panels[i].editing = false;
        this.panelData.panels[i].icon = "mdi-pencil-outline";
        this.panelData.panels[i].checked = false;
      }
    }
  },
  beforeUnmount() {
    this.$store.commit("setPanelData", this.panelData);
  },
};
</script>
