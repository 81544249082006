import axiosWrapper from "../axios/axiosWrapper";
const documentsBucket = "ipbrary-raw-data";

const getDefaultSearchState = () => {
  return {
    searchResults: null,
    searchQuery: null,
    searchable: true,
    searchMode: null,
  };
};

// initial state
const state = getDefaultSearchState();

const getters = {
  stateSearchResults: (state) => state.searchResults,
  stateSearchQuery: (state) => state.searchQuery,
  stateSearchable: (state) => state.searchable,
  stateSearchMode: (state) => state.searchMode,
};

const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  // eslint-disable-next-line no-empty-pattern
  async submitRating({}, { search_result_id, rating }) {
    let payload = {
      search_result_id: search_result_id,
      rating: rating,
    };

    return await axiosWrapper.post("/search/rate", payload);
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  async search({ commit }, { parentFolderId, documentId, key }) {
    const payload = {
      folder_id: parentFolderId,
      document_id: documentId,
      searchKey: key,
      override_search_method: "keyword",
    };

    const response = await axiosWrapper.post(`/search/`, payload);
    commit("setSearchResults", response.data);
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async highlightedPdf({}, { documentId, keyElements }) {
    const payload = {
      document_id: documentId,
      key_elements: keyElements,
      documents_bucket: documentsBucket,
    };

    return await axiosWrapper.post(
      `/search/highlighted_pdf/`,
      payload,
      null,
      "arraybuffer"
    );
  },
  /**
   *
   * @param {*} param0
   * @returns
   */
  // eslint-disable-next-line no-empty-pattern
  async reportIssue({}, { name, folderId, issueType, issueInfo }) {
    const payloadData = {
      name: name,
      folder_id: folderId,
      issue_type: issueType,
      issue_info: issueInfo,
    };

    try {
      return await axiosWrapper.post("/reported_issue/", payloadData);
    } catch (error) {
      return false;
    }
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  setSearchResults(state, payload) {
    state.searchResults = payload;
  },
  /**
   *
   * @param {*} state
   * @param {*} searchQuery
   */
  setSearchQueryObject(state, searchQueryObject) {
    state.searchQuery = searchQueryObject.searchQuery.trim();
    state.searchMode = searchQueryObject.searchMode;
  },
  /**
   *
   * @param {*} state
   * @param {*} isSearchable
   */
  setSearchable(state, isSearchable) {
    state.searchable = isSearchable;
  },

  resetSearchState(state) {
    Object.assign(state, getDefaultSearchState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
