<template>
  <div class="d-flex flex-column text-contrast">
    <div class="name-button-layout">
      <div v-if="tableName" class="table-name text-h5">
        {{ tableName }}
      </div>
      <div
        class="control-buttons"
        v-if="controlButtons && controlButtons.length > 0"
      >
        <v-btn
          v-for="button in controlButtons"
          :key="button.descr"
          :title="button.title"
          :prepend-icon="button.icon"
          :disabled="!button.enabled"
          :hidden="button.hideButton || false"
          class="my-3 mr-2"
          color="button-primary"
          variant="elevated"
          @click="executeButtonAction(button.descr)"
        >
          {{ button.label }}
        </v-btn>
      </div>
    </div>

    <v-sheet elevation="2" class="p-3" v-if="content && content.length > 0">
      <v-table
        class="table-height text-black"
        :class="customTableStyle"
        theme="light"
        :density="density"
        :height="calcHeight"
        fixed-header
        hover
      >
        <thead :hidden="hideHeader">
          <tr>
            <th
              :width="column.width || 'auto'"
              v-for="column in header"
              :key="column.descr"
              color="checkbox-primary"
              :class="{
                'text-center': column.center,
                'small-width': column.multiselect,
              }"
            >
              {{ column.name }}
              <!--a column can be a multiselect column-->
              <v-checkbox
                v-if="column.multiselect"
                v-model="selectAll"
                @change="select"
                :class="{
                  'checkbox-style': true,
                  'small-width': column.multiselect,
                }"
                hide-details="true"
              ></v-checkbox>
              <!--a column can be sortable, actively from the user or from the code-->
              <v-icon
                v-if="column.sort && column.activeSort"
                color="icon-primary"
                size="xs"
                @click="toggleSort(column)"
                >{{ sortIcon(column.sort) }}
              </v-icon>
            </th>
          </tr>
        </thead>
        <tbody style="color: black">
          <tr v-for="(item, index) in content" :key="index">
            <td
              v-for="column in header"
              :key="column.descr"
              :style="[
                tableConfig.clickRow && column.descr !== 'action'
                  ? 'cursor:pointer'
                  : 'cursor:default',
              ]"
            >
              <!--a column can be a multiselect column-->
              <v-sheet
                v-if="column.multiselect"
                style="background: transparent"
              >
                <v-checkbox
                  :class="{
                    'checkbox-style': true,
                    'small-width': column.multiselect,
                  }"
                  v-model="item.isChecked"
                  color="checkbox-primary"
                  hide-details="true"
                  @change="itemSelected(item, $event)"
                ></v-checkbox>
              </v-sheet>
              <!-- a column can contain formated value with optional prepending icon and row clik emitter-->
              <v-sheet
                v-if="
                  column.descr !== 'action' &&
                  column.descr !== 'chips' &&
                  !column.multiselect
                "
                class="row-style"
                @click="rowClicked(item)"
              >
                <div>
                  <div v-if="!item.editing">
                    <v-icon :class="item.customStyle" v-if="column.useIcon">{{
                      item.icon
                    }}</v-icon>
                    {{ formattedValue(item[column.descr], column.type) }}
                  </div>
                  <v-form
                    @submit.prevent="submit(item)"
                    v-if="item.editing"
                    :ref="`formInput${item.id}`"
                  >
                    <v-text-field
                      type="text"
                      density="compact"
                      autocomplete="off"
                      hide-details
                      :ref="`textInput${item.id}`"
                      variant="underlined"
                      :readonly="inputReadonly(column, item)"
                      :class="{
                        plainTextInput: item.editing,
                      }"
                      v-model="item[column.descr]"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon v-if="item.editing">
                          mdi-pencil-outline
                        </v-icon>
                      </template>
                    </v-text-field>
                  </v-form>
                </div>
              </v-sheet>
              <!--a column can contain chips-->
              <v-sheet
                v-if="column.descr === 'chips'"
                class="row-style d-flex flex-row justify-content-center"
              >
                <v-chip
                  v-for="chip in item.chips"
                  :key="chip.name"
                  label
                  :color="chip.color"
                  :class="chip.class"
                  :append-icon="chip.icon"
                  >{{ chip.name }}
                  <v-tooltip
                    :text="chip.text"
                    activator="parent"
                    :location="chip.location"
                  >
                  </v-tooltip>
                </v-chip>
              </v-sheet>
              <!--a column can contain actions-->
              <v-sheet
                v-if="column.descr === 'action'"
                class="row-style d-flex flex-row justify-content-center"
              >
                <v-btn
                  v-for="action in item.actions"
                  class="d-flex my-auto ml-2"
                  size="x-small"
                  :title="actionTitle(action.actionName)"
                  :key="action"
                  :icon="actionIcon(action.actionName)"
                  color="button-primary"
                  :disabled="!action.actionEnabled"
                  @click="executeAction(action.actionName, item)"
                >
                </v-btn>
              </v-sheet>
              <!--a column can be a dynamic component-->
              <v-sheet v-if="column.isDynamic">
                <keep-alive>
                  <component
                    :is="loadDynamicComponent(column.component, item)"
                    v-bind="item.componentProps"
                  ></component>
                </keep-alive>
              </v-sheet>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-sheet>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import _ from "lodash";
export default {
  name: "TableComponent",
  computed: {
    /**
     *
     */
    calcHeight() {
      // row height: 36
      //header height: 40
      const rowHeight = this.density === "compact" ? 36 : 52;
      let headerHeight = 0;
      if (!this.hideHeader) {
        headerHeight = this.density === "compact" ? 40 : 56.99;
      }

      if (this.content.length * rowHeight + headerHeight > this.mxHeight) {
        //return max height
        return this.mxHeight;
      } else {
        //return height according to how many elements we have
        return "auto";
      }
    },
  },
  props: {
    tableConfig: null,
    customTableStyle: {},
    //max height in vh
    mxHeight: { type: Number, default: 0 },
    density: {
      type: String,
      default: "default",
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      header: null,
      content: null,
      actions: null,
      controlButtons: null,
      selectAll: false,
      fieldEditing: false,
      tableName: null,
    };
  },
  methods: {
    /**
     *
     * @param {*} item
     */
    submit(item) {
      this.$emit("updateValue", { itemToUpdate: item });
    },
    /**
     *
     * @param {*} editableColumn
     * @param {*} item
     */
    inputReadonly(editableColumn, item) {
      if (!editableColumn) {
        return true;
      } else if (item.editing) {
        this.$nextTick(() => {
          const ref = this.$refs[`textInput${item.id}`];
          ref[0].focus();
        });
        return false;
      } else {
        return true;
      }
    },
    /**
     *
     */
    select() {
      for (let i in this.content) {
        this.content[i].isChecked = this.selectAll;
      }

      const itemsChecked = this.content.filter((el) => {
        return el.isChecked === true;
      });

      this.controlButtons.forEach((button) => {
        if (button.descr === "deleteItems") {
          button.enabled = itemsChecked.length > 0;
        }
      });

      this.$emit("allSelected", itemsChecked);
    },
    /**
     *
     */
    itemSelected(item, event) {
      const elementsChecked = this.content.filter((el) => {
        return el.isChecked == true;
      });

      this.selectAll = this.content.length === elementsChecked.length;
      item.isChecked = event.target.checked;

      this.controlButtons.forEach((button) => {
        if (button.descr === "deleteItems") {
          button.enabled = elementsChecked.length > 0;
        }
      });
      this.$emit("allSelected", elementsChecked);
    },
    /**
     *
     * @param {*} action
     */
    executeButtonAction(action) {
      this.$emit(action);
    },
    /**
     *
     * @param {*} action
     * @param {*} element
     */
    executeAction(action, element) {
      this.$emit(action, element);
    },
    /**
     *
     * @param {*} action
     */
    actionTitle(action) {
      const act = this.actions.filter((act) => {
        if (act.action === action) return act;
      });
      return act[0].title;
    },
    /**
     *
     * @param {*} action
     */
    actionIcon(action) {
      const act = this.actions.filter((act) => {
        if (act.action === action) {
          return act;
        }
      });
      return act[0].icon;
    },
    /**
     *
     * @param {*} item
     */
    rowClicked(item) {
      if (this.tableConfig.clickRow) {
        this.$emit("rowClicked", item);
      }
    },
    /**
     *
     * @param {*} property
     */
    dynamicSort(column) {
      const property = column.sortBy;

      let sortOrder = 1;

      if (column.sort === "desc") {
        sortOrder = -1;
      }
      this.content.sort(function (a, b) {
        let result = 1;
        switch (column.type) {
          // depending on the column type, different calculations
          case "datetime":
            result = Date.parse(a[property]) - Date.parse(b[property]);
            break;
          case "number":
            result = a[property] - b[property];
            break;
          case "string":
            // use this for case insensitive comparison
            result = a[property].localeCompare(b[property], undefined, {
              sensitivity: "base",
            });
            break;
        }
        // revert the order according to the sorting direction
        return result * sortOrder;
      });
      return this.content;
    },
    /**
     *
     * @param {*} column
     */
    toggleSort(column) {
      if (column.sort == "desc") {
        column.sort = "asc";
      } else {
        column.sort = "desc";
      }
      // reset all other icons to avoid sort confusion to the user
      this.header.forEach((element) => {
        if (element.name !== column.name) {
          element.sort = "default";
        }
      });

      this.dynamicSort(column);
    },
    /**
     *
     * @param {*} direction
     */
    sortIcon(direction) {
      switch (direction) {
        case "asc":
          return "mdi-sort-ascending";
        case "desc":
          return "mdi-sort-descending";
        default:
          return "mdi-sort";
      }
    },
    /**
     *
     * @param {*} value
     * @param {*} columnType
     */
    formattedValue(value, columnType) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      switch (columnType) {
        case "datetime":
          return value
            ? new Date(value).toLocaleDateString("en-GB", options)
            : "";
        default:
          return value;
      }
    },
    /**
     *
     */
    updateSelectedItems() {
      if (this.content.length === 0) this.selectAll = false;
    },
    /**
     *
     */
    loadDynamicComponent(component, item) {
      if (component.name && item != null) {
        return defineAsyncComponent(() =>
          import(`@/${component.path}/${component.name}.vue`)
        );
      }
    },
  },
  watch: {
    tableConfig: {
      handler(newConfig) {
        this.content = _.cloneDeep(newConfig.content);

        if (this.controlButtons) {
          this.controlButtons.forEach((button) => {
            if (button.descr === "deleteItems") {
              button.hideButton = this.content.length === 0;
            }
          });
        }

        this.updateSelectedItems();
        if (this.tableConfig.autoSort) {
          this.header.forEach((column) => {
            if (column.sort) {
              this.dynamicSort(column);
            }
          });
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.tableConfig) {
      this.header = _.cloneDeep(this.tableConfig.header);
      this.content = _.cloneDeep(this.tableConfig.content);
      this.actions = _.cloneDeep(this.tableConfig.actions);
      this.controlButtons = _.cloneDeep(this.tableConfig.controlButtons);
      this.tableName = _.cloneDeep(this.tableConfig.tableName);

      if (this.tableConfig.showDeleteAll) {
        this.controlButtons.push({
          descr: "deleteItems",
          label: "Delete items",
          icon: "mdi-delete-outline",
          title: "Delete selected item(s)",
          enabled: false,
          hideButton: this.content.length === 0,
        });
      }
    }
  },
};
</script>

<style>
.small-width {
  padding-left: 6px !important;
  padding-right: 6px !important;
  width: 50px !important;
}
.name-button-layout {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.table-name {
  text-wrap: no-wrap;
  padding-left: 1%;
}
.row-style {
  width: 100;
  background-color: transparent !important;
}
.control-buttons {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-left: auto;
  min-width: max-content;
  max-width: min-content;
  min-height: 70px;
}
.table-height {
  max-height: 100%;
}
.plainTextInput {
  padding-top: 0 !important;
}
</style>
