import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/css/global.css";

import router from "./router";
import store from "./store";
import { translate, formatLink } from "./filters/templateFilters";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

//bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "@mdi/font/css/materialdesignicons.css";

const myCustomLightTheme = {
  dark: false,
  colors: {
    surface: "#FFFFFF",
    primary: "#273746",
    "table-primary": "#273746",
    "button-primary": "#273746",
    "button-secondary": "#fa9f8a",
    "icon-primary": "#f7603e",
    "text-secondary": "#f7603e",
    "typo-ternary": "#B2EB87", // the metis color
    "secondary-lighten-1": "#fa9f8a",
    "primary-darken-1": "#212529",
    "primary-ligthen-1": "#f5f5f5",
    secondary: "#566573",
    error: "#B00020",
    info: "#90CAF9",
    success: "#689F38",
    warning: "#FFB300",
    border: "#273746",
    danger: "#D32F2F",
    dark: "#343A40",
    "search-high": "#689F38",
    "search-mid": "#AEEA00",
    "search-low": "#FFB300",
  },
};
const vuetify = createVuetify({
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
  defaults: {
    VBtn: {
      color: "#273746",
      font: "#FFFFFF",
    },
  },
  components,
  directives,
});

const app = createApp(App);
app.config.globalProperties.$appName = "IPBrary";
app.config.globalProperties.$filters = {
  translate,
  formatLink,
};
app.config.errorHandler = (err, vm, info) => {
  console.debug("Error:", err);
  console.debug("Vue component:", vm);
  console.debug("Additional info:", info);
};

app.use(vuetify);
app.use(store);
app.use(router);
app.mount("#app");
