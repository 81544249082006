<template>
  <div class="m-2 p-2">
    <!-- component table -->
    <componentsTable
      :tableConfig="tableConfig"
      :hideHeader="false"
      v-on:removeComponent="openDeleteDialog($event)"
      v-on:editComponent="enableEdit($event)"
      v-on:updateValue="updateValue($event)"
      v-on:newComponent="addComponentDialog = true"
      v-on:deleteItems="deleteItemsModal = true"
      v-on:allSelected="setSelectedItems($event)"
      v-on:invalidInput="this.$emit('invalidInput')"
      :mxHeight="mxHeight"
    ></componentsTable>

    <modal
      v-if="deleteItemDialog"
      :title="deleteDialogTitle"
      :content="deleteDialogContent"
      :type="'confirmation'"
      :ok-button-label="'Delete'"
      v-model="deleteItemDialog"
      v-on:ok="deleteItem()"
      v-on:cancel="deleteItemDialog = false"
    ></modal>

    <deleteItemsModal
      v-model="deleteItemsModal"
      v-on:close="deleteItemsModal = false"
      v-on:confirm="deleteItems"
      :modal-class="'medium-modal'"
      :scrollable="true"
      :persistent="false"
      :contained="false"
      :showCloseBtn="true"
      :showConfirmBtn="true"
      :showCloseBtnHeader="false"
    >
      <template v-slot:header>
        <div class="d-flex">
          <h4>{{ this.$filters.translate("ipbrary_032") }}</h4>
        </div>
      </template>

      <template v-slot:body>
        <deleteComponentsTable
          style="overflow-y: auto"
          :tableConfig="deleteItemsTableConfig"
          :density="'compact'"
          :customTableStyle="{ tableLightStyleDelete: true }"
          :mxHeight="400"
        ></deleteComponentsTable>
      </template>
      <template v-slot:footer>
        <br />
      </template>
    </deleteItemsModal>

    <addModal
      v-if="addComponent"
      ref="addComponentModal"
      :title="this.$filters.translate('ipbrary_024')"
      :icon="'mdi-plus'"
      :label="this.$filters.translate('ipbrary_025')"
      :ok-button-label="this.$filters.translate('ipbrary_026')"
      :rules="[rules.required, rules.duplicate, rules.invalidCharacter]"
      v-model="addComponentDialog"
      v-on:ok="addComponent($event)"
      v-on:cancel="addComponentDialog = false"
    ></addModal>
  </div>
</template>

<script>
import TableComponent from "@/components/ui/TableComponent.vue";
import AddElementModal from "@/components/ui/modals/AddElementModal.vue";
import BaseModal from "../../../../../components/ui/modals/BaseModal.vue";
import ModalComponent from "../../../../../components/ui/modals/ModalComponent.vue";

export default {
  name: "ComponentListView",

  components: {
    addModal: AddElementModal,
    deleteComponentsTable: TableComponent,
    componentsTable: TableComponent,
    deleteItemsModal: BaseModal,
    modal: ModalComponent,
  },
  data() {
    return {
      tableConfig: {
        content: [],
        header: [],
        actions: [],
        controlButtons: [],
      },
      deleteItemDialog: false,
      selectedItem: null,
      addComponentDialog: false,
      deleteDialogTitle: "",
      deleteDialogContent: "",
      deleteItemsModal: false,
      rules: {
        required: (value) => !!value || this.$filters.translate("ipbrary_027"),
        duplicate: (value) =>
          !this.checkComponentExists(value) ||
          this.$filters.translate("ipbrary_028"),
        invalidCharacter: (value) => {
          const reg = new RegExp('^[^\\/?%*:|"<>]+$');
          return reg.test(value) || this.$filters.translate("ipbrary_029");
        },
      },
      deleteItemsTableConfig: {
        content: [],
        header: [
          {
            name: this.$filters.translate("ipbrary_038"),
            descr: "name",
            useIcon: true,
          },
        ],
      },
    };
  },
  computed: {
    addComponents() {
      const show = this.tableConfig
        ? this.tableConfig.controlButtons.some(
            (button) => button.descr == "newComponent"
          )
        : false;
      return show;
    },
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    mxHeight: {
      type: Number,
      default: 700,
    },
    componentTableConfig: null,
  },
  watch: {
    componentTableConfig: {
      handler(newContent) {
        this.tableConfig = newContent;
      },
      deep: true,
    },
  },

  methods: {
    /**
     *
     */
    enableEdit(item) {
      item.editing = true;
    },
    updateOld(itemObj) {
      itemObj.item.oldValue = itemObj.oldValue;
    },
    /**
     *
     * @param {*} value
     */
    checkComponentExists(value) {
      let componentExists =
        this.tableConfig.content.length > 0
          ? this.tableConfig.content.some(
              (component) => component.name.trim() === value.trim()
            )
          : false;

      return componentExists;
    },
    /**
     *
     * @param {*} item
     */
    openDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteDialogTitle = `${this.$filters.translate(
        "ipbrary_030"
      )} ${this.$filters.translate("ipbrary_038")}
      `;
      this.deleteDialogContent = `${this.$filters.translate(
        "ipbrary_031"
      )} ${this.$filters.translate("ipbrary_038").toLowerCase()}?`;
      this.deleteItemDialog = true;
    },
    /**
     *
     */
    updateValue(componentUpdate) {
      componentUpdate.itemToUpdate.editing = false;
      //update state
      this.$emit("componentUpdated", componentUpdate.itemToUpdate);
    },
    /**
     *
     */
    deleteItem() {
      this.$emit("componentDeleted", this.selectedItem);
      this.deleteItemDialog = false;
    },
    /**
     *
     * @param {*} newFolder
     */
    addComponent(item) {
      this.emptyResults = false;

      const newComponent = {
        name: item,
        actions: null,
        id: null,
      };

      this.$emit("componentAdded", newComponent);
      this.addComponentDialog = false;
    },
    /**
     *
     */
    deleteItems() {
      this.$emit("componentsDeleted", this.deleteItemsTableConfig.content);
      this.deleteItemsModal = false;
    },
    /**
     *
     * @param {*} items
     */
    setSelectedItems(itemsChecked) {
      this.deleteItemsTableConfig.content = itemsChecked;
    },
  },
  created() {
    this.tableConfig = { ...this.componentTableConfig };
  },
  mounted() {},
};
</script>
