<template>
  <v-row class="bg-whitesmoke h-100 m-0">
    <v-col cols="11" class="d-flex flex-column mx-auto p-3 my-2">
      <div>
        <v-img :src="logoImage" style="height: 150px"></v-img>
      </div>
      <div class="search-prompts">
        <searchInput v-model="query" v-if="!patentCollectionLocal" :max-width="600" :min-width="300" :searchQuery="query"
          :label="'ipbrary_048'" v-on:doSearch="doSearch" :variant="'solo'">
        </searchInput>

        <patentSearchInput v-model="query" v-if="patentCollectionLocal" :max-width="600" :min-width="300"
          :searchQuery="query" v-on:doLlmSearch="doLlmSearch" v-on:imageUpload="doImageToTextSearch">
        </patentSearchInput>

        <v-switch :label="switchLabel" class="p-1 m-1 switch-style" v-model="patentCollectionLocal" color="primary"
          hide-details></v-switch>
      </div>

      <searchErrorModal :name="null" :folderId="this.currentFolderId" :issueType="switchLabel"
        :issueInfo="{ search_query: this.query }" v-on:issueSubmitted="resetSearchState" v-model="errorDialog"
        v-on:ok="closeErrorModal"></searchErrorModal>

      <div class="d-flex flex-column align-items-center">
        <v-icon class="bot-icon" v-if="showBotIcon">mdi-robot-outline</v-icon>
        <div class="h5 m-1" v-if="searchResponseText">
          <span> {{ searchResponseText }}</span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import logoImg from "@/assets/images/ipbrary_logo-positive_mediun.png";
import SearchInputComponent from "@/components/search/SearchInputComponent.vue";
import SearchErrorModal from "@/components/search/SearchErrorModal.vue";
import PatentCollectionInputComponent from "@/components/search/PatentCollectionInputComponent.vue";

export default {
  name: "LandingSearchView",

  components: {
    searchInput: SearchInputComponent,
    patentSearchInput: PatentCollectionInputComponent,
    searchErrorModal: SearchErrorModal,
  },
  props: {
    patentCollection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      patentCollectionLocal: this.patentCollection,
      logoImage: logoImg,
      errorDialog: false,
      query: null,
      searchResponseText: null,
      showBotIcon: false,
    };
  },
  watch: {
    patentCollection: {
      immediate: true,
      handler(newVal) {
        this.patentCollectionLocal = newVal;
      }
    },
    patentCollectionLocal(newVal) {
      this.$emit('update:patentCollection', newVal);
      this.resetGui();
    },
    imageSearchResult: {
      async handler(newValue) {
        this.query = newValue;
        if (newValue) this.doLlmSearch({ searchQuery: newValue });
      }
    }
  },
  computed: {
    ...mapGetters({
      homeId: "stateHomeId",
      subFolders: "stateSubFolders",
      currentFolderId: "stateCurrentFolderId",
      searchResults: "stateSearchResults",
      searchQuery: "stateSearchQuery",
      llmSearchQuery: "stateLlmSearchQuery",
      componentListResponse: "stateComponentListResponse",
      imageSearchResult: "stateImageSearchResult",
    }),
    switchLabel() {
      return this.patentCollectionLocal
        ? this.$filters.translate("ipbrary_035")
        : this.$filters.translate("ipbrary_036");
    },
  },
  methods: {
    ...mapActions(["home", "search", "llmSearch", "imageSearch"]),
    resetSearchState() {
      this.$store.commit("resetLlmSearchState");
      this.$store.commit("resetImageSearchState");
      this.$store.commit("resetSearchState");
    },
    resetGui() {
      this.query = null;
      this.searchResponseText = null;
      this.showBotIcon = false;
      this.resetSearchState();
    },
    closeErrorModal() {
      this.errorDialog = false;
      this.resetGui();
    },
    async doSearch(searchObject) {
      this.query = searchObject.searchQuery;
      this.$store.commit("setSearchQueryObject", searchObject);
      try {
        await this.search({
          parentFolderId: this.currentFolderId,
          key: this.query,
        });

        if (this.searchResults && this.searchResults.items.length != 0) {
          this.$router.push({
            name: "FolderSearchResults",
            params: {
              search_id: this.searchResults.search_query_id,
              folder_id: this.currentFolderId,
            },
            state: { document_id: "" },
          });
        } else {
          this.searchResponseText = this.$filters.translate("ipbrary_009");
        }
      } catch (error) {
        this.errorDialog = true;
      }
    },
    async doLlmSearch(searchObject) {
      this.query = searchObject.searchQuery;
      this.$store.commit("setLlmSearchQuery", this.query);

      try {
        await this.llmSearch({ userPrompt: this.query });

        if (this.componentListResponse.components_list.length > 0) {
          this.$router.push({
            name: "ComponentList",
          });
        } else {
          this.showBotIcon = true;
          this.searchResponseText = this.componentListResponse.text;
        }
      } catch (error) {
        this.errorDialog = true;
      }
    },
    async doImageToTextSearch(file) {
      await this.imageSearch(file);
    },
  },
  async created() {
    this.resetSearchState();
    await this.home();
    this.$store.commit("setCurrentFolderId", this.homeId);
  },
};
</script>

<style>
.search-prompts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-self: center;
  align-items: baseline;
}

.switch-style {
  max-width: 200px;
  justify-items: center;
}

.bot-icon {
  font-size: 4rem !important;
  margin: 2rem auto 0.3rem auto;
}
</style>
