<template>
    <v-card class="marketplace" :style="{ backgroundImage: `url(${marketplaceImage})` }">
      <div class="overlay"></div>
      <div class="marketplace-card">
        <v-btn class="explore-btn">Explore!</v-btn>
        <h3 class="marketplace-title">Patent Marketplace</h3>
        <p class="marketplace-subtitle">120,000 Patents</p>
      </div>
    </v-card>
  </template>
  
  <script>
  export default {
    name: "MarketplaceCard",
    props: {
      marketplaceImage: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .marketplace {
    background-size: cover !important;
    background-position: center !important;
    color: white !important;
    text-align: center;
    border-radius: 20px !important;
    margin-top: 40px;
    position: relative;
  }
  
  .marketplace-card {
    height: 370px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    position: relative;
    z-index: 2;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .explore-btn {
    background: #62CD14FF !important;
    border-radius: 20px !important;
    color: white !important;
  }
  
  .marketplace-title {
    margin: 10px 0;
    font-size: 44px;
    line-height: 60px;
    font-weight: 700;
  }
  
  .marketplace-subtitle {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
  }
  </style>
  