<template>
  <breadcrumbs
    :breadcrumbsProps="breadcrumbsList"
    :mode="'mini'"
    :disableLinks="disableLinks"
    :prependIcon="breadcrumbsList.length > 0 ? 'mdi-folder-outline' : null"
    :customColor="customColor"
    :dividerIcon="dividerIcon"
  ></breadcrumbs>
</template>

<script>
import BreadcrumbsComponent from "./BreadcrumbsComponent.vue";

export default {
  name: "MiniBreadcrumbsComponent",

  components: {
    breadcrumbs: BreadcrumbsComponent,
  },

  data() {
    return {
      breadcrumbsList: [],
    };
  },
  props: {
    parents: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function. The function receives the raw
      // props received by the component as the argument.
      default() {
        return [];
      },
    },
    disableLinks: {
      type: Boolean,
      default: true,
    },
    dividerIcon: {
      type: String,
      default: null,
    },
    customColor: {
      type: String,
      default: "white",
    },
  },
  watch: {
    parents(newValue) {
      if (newValue) this.setBreadCrumbList(newValue);
    },
  },
  methods: {
    /**
     *
     * @param {*} parents
     */
    setBreadCrumbList(parents) {
      this.breadcrumbsList = [];
      parents.forEach((parent) => {
        const breadcrumb = {
          title: parent.folder_name,
          disabled: this.disableLinks,

          to: {},
          folder_id: parent.folder_id,
        };
        this.breadcrumbsList.push(breadcrumb);
      });
    },
  },

  created() {
    this.setBreadCrumbList(this.parents);
  },
};
</script>
