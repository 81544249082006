<template>
    <div>
      <h4 class="title">{{ title }}</h4>
      <p class="subtitle">{{ subtitle }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "TitleSubtitle",
    props: {
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .title {
    margin: 0;
  }
  
  .subtitle {
    margin: 0;
  }
  </style>
  