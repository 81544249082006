import { getTranslatedText } from "../translations/ipbraryTranslations";
import store from "@/store";

/**
 *
 * @param {*} id
 * @returns
 */
function translate(id) {
  const defaultLanguage = store.getters.language;

  return getTranslatedText(defaultLanguage, id);
}

/**
 *
 * @param {*} textToFormat
 * @param {*} links
 * @param {*} customClass
 * @returns
 */
function formatLink(textToFormat, links, customClass) {
  // first get translation
  let translatedText = translate(textToFormat);

  links.forEach((link) => {
    // build link
    const target = link.openInTab ? "_blank" : "_self";
    const classes = customClass ? customClass : "text-typo-primary";
    const tag = `<a class="${classes}" target=${target} href=${link.href}>${link.placeholder}</a>`;

    // build vhtlm
    translatedText = translatedText.replace(`link[${link.placeholder}]`, tag);
  });

  return translatedText;
}

export { translate, formatLink };
