import axiosWrapper from "../axios/axiosWrapper";

const getDefaultSearchQueryState = () => {
  return {
    searchQueryList: null,
    querySearchResults: null,
  };
};

// initial state
const state = getDefaultSearchQueryState();

const getters = {
  stateSearchQueryList: (state) => state.searchQueryList,
  stateQuerySearchResults: (state) => state.querySearchResults,
};

const actions = {
  /**
   *
   * @param {*} param0
   */
  async getSearchQueries({ commit }) {
    let data = await axiosWrapper.get(`/search_query/all`, null, null, {
      "Content-Type": "application/json",
    });

    commit("setSearchQueries", data);
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async deleteSearchQuery({}, searchQueryId) {
    await axiosWrapper.delete(
      `/search_query/:search_query_id`,
      { search_query_id: searchQueryId },
      null,
      { "Content-Type": "application/json" }
    );
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  async getSearchResultsByQuery({ commit }, searchQueryId) {
    let data = await axiosWrapper.get(
      `/search_query/:search_query_id/search_results`,
      { search_query_id: searchQueryId },
      null,
      { "Content-Type": "application/json" }
    );

    commit("setQuerySearchResults", data.data);
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  setSearchQueries(state, payload) {
    state.searchQueryList = payload.data.elements;
  },
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  setQuerySearchResults(state, payload) {
    state.querySearchResults = payload;
  },

  resetSearchQueryState(state) {
    Object.assign(state, getDefaultSearchQueryState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
