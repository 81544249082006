import inspectSearchService from "./inspectSearchService";

class BaseInspectSearchConfiguration {
  /**
   *
   */
  createPaylod() {
    this._WARNING("createPayload(items, documentId)");
  }
}

class FolderInspectSearchConfigruation extends BaseInspectSearchConfiguration {
  /** @inheritdoc */
  createPayload(searchResults, fileName, keySentencesLoaded) {
    return inspectSearchService.folderSearchPayload(
      searchResults,
      fileName,
      keySentencesLoaded
    );
  }
}

class DocumentInspectSearchConfiguration extends BaseInspectSearchConfiguration {
  /** @inheritdoc */
  createPayload(searchResults) {
    return inspectSearchService.documentSearchPayload(searchResults);
  }
}

class ConfigurationFactory {}

// default instance
ConfigurationFactory.prototype.configClass = FolderInspectSearchConfigruation;

// class constructor
ConfigurationFactory.prototype.createConfig = function (documentId) {
  switch (documentId) {
    case null:
    case "":
      this.configClass = FolderInspectSearchConfigruation;
      break;
    default:
      this.configClass = DocumentInspectSearchConfiguration;
      break;
  }
  return new this.configClass(documentId);
};
const configFactory = new ConfigurationFactory();
export default configFactory;
