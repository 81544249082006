import axiosWrapper from "../axios/axiosWrapper";

const getDefaultImageSearchState = () => {
  return {
    imageSearchResult: null,
  };
};

// initial state
const state = getDefaultImageSearchState();

const getters = {
  stateImageSearchResult: (state) => state.imageSearchResult,
};

const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-unused-vars
  async imageSearch({ commit }, image) {
    const result = await axiosWrapper.postForm(`/image_to_text/`, {
      image_file: image,
    });

    commit("setImageSearchResult", result.data);
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} componentList
   */
  setImageSearchResult(state, searchResult) {
    state.imageSearchResult = searchResult["message"];
  },
  /**
   *
   * @param {*} state
   */
  resetImageSearchState(state) {
    Object.assign(state, getDefaultImageSearchState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
