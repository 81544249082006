<template>
  <div id="searchInspect" class="h-100 w-100 d-flex flex-wrap mx-2 p-1">
    <documentViewer
      class="flex-child-search"
      :fileName="fileName"
      :pdfBinary="pdfBinary"
      :pageToShow="pageToShowComputed"
      v-on:resetPage="resetPage"
    />

    <searchResult
      class="flex-child-search flex-search-results"
      :class="{
        'align-items-center': pdfBinary.byteLength === 0,
        'result-position': customStyle && pdfBinary.byteLength !== 0,
      }"
      :style="{ top: topProp + 'px' }"
      v-if="searchResults"
      :searchResults="searchResults"
      :searchQuery="searchQuery"
      v-on:resultClicked="showResultOnDocument"
      v-on:resultRated="updateRating"
      v-on:keySentencesLoaded="setKeySentencesLoaded"
      :currentSearchPath="currentSearchPath"
    />
  </div>
</template>

<script>
import DocumentViewer from "../../document/DocumentViewerComponent.vue";
import SearchResults from "../SearchResultsComponent.vue";

export default {
  name: "InspectSearch",
  components: {
    documentViewer: DocumentViewer,
    searchResult: SearchResults,
  },
  computed: {
    pageToShowComputed: {
      get: function () {
        if (this.searchResults) {
          return this.page;
        }
        return 1;
      },
      set: function (value) {
        this.page = value;
      },
    },
  },
  props: {
    fileName: {
      default: null,
      type: String,
    },
    searchQuery: {
      default: null,
      type: String,
    },
    pdfBinary: {
      type: ArrayBuffer,
      default() {
        return new ArrayBuffer(0);
      },
    },
    searchResults: {
      type: Object,
      default() {
        return null;
      },
    },
    currentSearchPath: {
      type: Array,
      default() {
        return [];
      },
    },
    customStyle: {
      type: Boolean,
      default: true,
    },
    topProp: {
      type: Number,
      default: -65,
    },
  },
  watch: {
    searchResults: {
      handler(newValue) {
        if (newValue) {
          this.page = newValue.items[0].page;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      keySentencesLoaded: 10, // this is the same step as in the search result component
      page: 1,
    };
  },
  methods: {
    /**
     *
     * @param {*} absoluteIndex
     */
    async showResultOnDocument(absoluteIndex) {
      // go to page
      this.$emit("highlightResult", absoluteIndex);
      this.goToPage(absoluteIndex);
    },
    /**
     *
     * @param {*} keySentencesLoaded
     */
    async setKeySentencesLoaded(keySentencesLoaded) {
      // every time the results are expanded,
      // update progressively the highlighted text
      this.$emit("keySentencesLoadedInspect", keySentencesLoaded);
    },
    goToPage(index) {
      this.pageToShowComputed = this.searchResults.items[index - 1].page;
    },
    resetPage() {
      this.pageToShowComputed = 0;
    },
    updateRating({ rate, index }) {
      this.$emit("updateRating", { rate, index });
    },
  },
};
</script>
<style>
.flex-child-search {
  min-width: 370px;
  height: 71vh;
  flex: 1 1 50%;
  padding: 0 2%;
  display: flex;
  flex-direction: column;
}
.flex-search-results {
  align-self: flex-end;
}
@media (max-width: 788px) {
  .result-position {
    top: 0px !important;
  }
  .flex-search-results {
    align-self: flex-end;
    max-width: 100%;
  }
}
@media (min-width: 789px) {
  .result-position {
    position: relative;
  }
  .flex-search-results {
    align-self: flex-end;
    max-width: 50%;
  }
}
@media (min-height: 650px) {
  .result-position {
    position: relative;
  }
  .flex-child-search {
    min-width: 370px;
    height: 77vh; /**81 works well for search queries */
    flex: 1 1 50%;
    padding: 0 2%;
    display: flex;
    flex-direction: column;
  }
}
</style>
