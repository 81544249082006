<template>
    <v-col :cols="cols" :md="md" class="search-option">
        <v-card :style="option.style" class="search-card p-4">
            <div class="nav-card">
                <div class="icon-wrp">
                    <v-icon class="search-icon">{{ option.icon }}</v-icon>
                    <router-link class="detail-icon"
                        :to="{ name: option.link, query: { patentCollection: option.showPatentCollection } }">
                        <v-icon>mdi-arrow-right-thin-circle-outline</v-icon>
                    </router-link>
                </div>
                <div class="text-wrp">
                    <h3 class="search-title">{{ option.title }}</h3>
                    <p class="search-description">{{ option.description }}</p>
                </div>
            </div>
        </v-card>
    </v-col>
</template>
  
<script>
export default {
    name: "SearchOptionCard",
    props: {
        option: {
            type: Object,
            required: true,
        },
        cols: {
            type: Number,
            default: 12,
        },
        md: {
            type: Number,
            default: 4,
        },
    },
};
</script>
  
<style scoped>
.search-option {
    /* margin-bottom: 20px; */
}

.search-card {
    height: 260px !important;
    border-radius: 20px !important;
    box-shadow: none !important;
}

.icon-wrp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px !important;
}

.detail-icon {
    color: inherit !important;
}

.detail-icon .v-icon {
    width: 32px !important;
    font-size: 32px !important;
    height: auto;
}

.search-icon {
    margin-bottom: 10px;
    width: 75px !important;
    font-size: 75px !important;
    height: auto;
}

.search-title {
    margin-bottom: 10px;
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 700 !important;
}

.search-description {
    font-size: 16px !important;
    line-height: 28px !important;
}
</style>
  