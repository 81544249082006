<template>
  <v-row class="fill-row-height bg-primary h-100 m-auto">
    <v-col
      cols="12"
      class="p-0 d-flex align-items-center flex-column"
      style="justify-content: space-between"
    >
      <div class="h2 m-5 p-5">{{ this.$filters.translate("ipbrary_006") }}</div>
      <router-link
        to="/home"
        style="color: whitesmoke !important; text-decoration: none !important"
      >
        <div class="h4">
          <v-icon>mdi-arrow-left</v-icon>
          <span> {{ this.$filters.translate("ipbrary_007") }} </span>
        </div>
      </router-link>

      <div class="mt-5">
        <metis_footer :fontColor="'text-primary-ligthen-1'" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FooterComponent from "../components/ui/FooterComponent.vue";

export default {
  name: "PageNotFound",

  components: {
    metis_footer: FooterComponent,
  },
};
</script>
