<template>
  <v-container class="main-content">
    <h2 class="welcome-title">Welcome to IPBrary</h2>
    <v-card class="generate-card">
      <v-row align="center">
        <v-icon class="plus-icon">mdi-plus-circle-outline</v-icon>
        <title-subtitle title="Generate new Patent" subtitle="Non pariatur dolor dolor sit velit minim esse cupi" />
      </v-row>
    </v-card>
    <div class="search-options">
      <v-row>
        <search-option-card v-for="(option, index) in searchOptions.slice(0, 3)" :key="index" :option="option" :cols="12"
          :md="4" />
      </v-row>
      <v-row>
        <search-option-card v-for="(option, index) in searchOptions.slice(3, 5)" :key="index" :option="option" :cols="12"
          :md="6" />
      </v-row>
    </div>
    <marketplace-card :marketplaceImage="marketplaceImage" />
  </v-container>
</template>

<script>
import TitleSubtitle from "./components/TitleSubtitle.vue";
import SearchOptionCard from "./components/SearchOptionCard.vue";
import MarketplaceCard from "./components/MarketplaceCard.vue";
import logoImg from "@/assets/images/ipbrary_logo-positive_mediun.png";
import marketplaceImg from "@/assets/images/marketplace-banner.jpg";

export default {
  name: "HomeView",
  components: {
    TitleSubtitle,
    SearchOptionCard,
    MarketplaceCard,
  },
  data() {
    return {
      patentCollection: false,
      logoImage: logoImg,
      errorDialog: false,
      query: null,
      searchResponseText: null,
      showBotIcon: false,
      searchOptions: [
        {
          title: "AI Search - Text",
          description: "Labore prodient nisi fugiat nostrud sint",
          style: {
            backgroundColor: "#CCF7AEFF",
            color: "#37750CFF"
          },
          icon: "mdi-text-box-search",
          link: "LandingSearchView",
          showPatentCollection: 'false'
        },
        {
          title: "AI Search - Image",
          description: "Labore prodient nisi fugiat nostrud sint",
          style: {
            backgroundColor: "#D3C1FAFF",
            color: "#3F0EA6FF"
          },
          icon: "mdi-image-search",
          link: "LandingSearchView",
          showPatentCollection: 'false'
        },
        {
          title: "AI Search - Use Case",
          description: "Labore prodient nisi fugiat nostrud sint",
          style: {
            backgroundColor: "#FDCFC4FF",
            color: "#B12805FF"
          },
          icon: "mdi-briefcase-search",
          link: "LandingSearchView",
          showPatentCollection: 'false'
        },
        {
          title: "AI Patent Collection",
          description: "Labore prodient nisi fugiat nostrud sint",
          style: {
            backgroundColor: "#FAE7C0FF",
            color: "#98690CFF"
          },
          icon: "mdi-text-box-multiple-outline",
          link: "LandingSearchView",
          showPatentCollection: 'true'
        },
        {
          title: "Cooperate!",
          description: "Labore prodient nisi fugiat nostrud sint",
          style: {
            backgroundColor: "#B9E5FFFF",
            color: "#00659FFF"
          },
          icon: "mdi-handshake",
          link: "LandingSearchView",
          showPatentCollection: 'false'
        },
      ],
      marketplaceImage: marketplaceImg
    };
  }
};
</script>

<style>
.main-content {
  padding: 80px;
}

.welcome-title {
  font-size: 40px;
  line-height: 56px;
  font-weight: 700;
  color: #171A1FFF;
  text-align: center;
  margin: 20px 0;
}

.generate-card {
  background-color: #6D31EDFF !important;
  color: white !important;
  height: 100px !important;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px !important;
}

.generate-card .v-row {
  height: 100%;
  margin: 0 24px !important;
}

.plus-icon {
  font-size: 44px !important;
  margin-right: 20px;
}
</style>
