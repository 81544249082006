<template>
  <v-row no-gutters class="h-100 bg-page-primary overflow-auto">
    <v-col cols="12" class="d-flex flex-column h-100">
      <div class="search-container-style">
        <v-btn
          to="/home"
          variant="plain"
          prepend-icon="mdi-arrow-left"
          class="p-auto mx-4 my-2 text-decoration-none"
          width="200"
          rounded
        >
          {{ this.$filters.translate("ipbrary_034") }}
        </v-btn>

        <searchInput
          class="mx-2 px-4 w-100"
          :searchQuery="query"
          v-on:doSearch="submitSearch"
          :density="'compact'"
          :color="'input-primary'"
        ></searchInput>
      </div>

      <inspectSearch
        class="flex-grow-1"
        :fileName="selectedItem.fileName"
        :searchQuery="searchQuery"
        :pdfBinary="pdfBinary"
        :searchResults="searchResults"
        :customStyle="true"
        :topProp="top"
        v-on:updateRating="updateRating"
        v-on:keySentencesLoadedInspect="setKeySentencesLoaded"
        v-on:highlightResult="showResultOnDocument"
      />
    </v-col>

    <searchErrorModalComp
      :name="selectedItem.name"
      :folderId="currentFolderId"
      :issueType="'documentSearch'"
      :issueInfo="{ search_query: searchQuery }"
      v-model="searchErrorModal"
      v-on:ok="searchErrorModal = false"
    />

    <errorModalCmp
      :title="this.$filters.translate('ipbrary_017')"
      :content="this.errorModalText"
      :okButtonLabel="this.$filters.translate('ipbrary_020')"
      :cancelButtonLabel="this.$filters.translate('ipbrary_019')"
      v-model="errorModal"
      v-on:ok="errorModal = false"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InspectSearch from "../../../components/search/inspectSearch/InspectSearchComponent.vue";
import SearchInputComponent from "@/components/search/SearchInputComponent.vue";
import SearchErrorModal from "../../../components/search/SearchErrorModal.vue";
import ModalComponent from "../../../components/ui/modals/ModalComponent.vue";
import inspectSearchService from "../../../components/search/inspectSearch/services/inspectSearchService";

export default {
  name: "BaseSearchResults",
  components: {
    inspectSearch: InspectSearch,
    searchInput: SearchInputComponent,
    searchErrorModalComp: SearchErrorModal,
    errorModalCmp: ModalComponent,
  },
  computed: {
    ...mapGetters({
      searchQuery: "stateSearchQuery",
      searchResults: "stateSearchResults",
      searchMode: "stateSearchMode",
      currentFolderId: "stateCurrentFolderId",
      pdfBinary: "statePdfBinary",
    }),

    /**
     *
     */
    top() {
      return this.pdfBinary.byteLength === 0 ? 0 : -80;
    },
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    createPayload: {
      type: Function,
      default() {},
    },
    documentId: {
      type: String,
      default: null,
    },
  },
  watch: {
    /**
     *
     * @param {*} newValue
     */
    async searchQuery(newValue) {
      if (newValue) {
        // explore mode
        if (newValue.length > 0) {
          // perform search
          await this.submitSearch();
        }
      } else {
        this.$store.commit("setSearchResults", null);
      }
    },
  },
  data() {
    return {
      keySentencesLoaded: 10, // this is the same step as in the search result component
      errorModalText: this.$filters.translate("ipbrary_033"),
      query: null,
      errorModal: false,
      searchErrorModal: false,
      selectedItem: {
        fileName: null,
        page: null,
        document_id: null,
        keySentence: null,
        searchMode: null,
      },
    };
  },
  methods: {
    ...mapActions(["search", "highlightedPdf", "getPdf"]),
    /**
     *
     */
    async submitSearch(searchObject) {
      // --- set up ---
      this.page = 1;
      this.query = searchObject.searchQuery;
      this.$store.commit("setSearchQueryObject", searchObject);
      // search
      try {
        await this.search({
          parentFolderId: this.currentFolderId,
          documentId: window.history.state.document_id,
          key: this.searchQuery,
        });

        this.removeHomeParent();
        // set variables
        if (this.searchResults && this.searchResults.items.length > 0) {
          inspectSearchService.setCurrentDocument(
            this.selectedItem,
            this.searchResults.items[0].document_id,
            this.searchResults.items[0].page,
            this.searchResults.items[0].pdf,
            this.searchResults.items[0].sentence,
            this.searchMode
          );

          if (this.searchResults.items.length > 0) {
            await inspectSearchService.highlightPdf(
              this.searchResults.items,
              this.selectedItem,
              this.keySentencesLoaded,
              this.highlightedPdf,
              this.createPayload
            );
          }
        }
      } catch (error) {
        // error in search
        this.searchErrorModal = true;
      }
    },

    /**
     * ok
     * @param {*} param0
     */
    updateRating({ rate, index }) {
      this.searchResults.items[index].rating = rate;
    },
    /**
     * ok
     * @param {*} keySentencesLoaded
     */
    async setKeySentencesLoaded(keySentencesLoaded) {
      // every time the results are expanded,
      // update progressively the highlighted text
      this.keySentencesLoaded = keySentencesLoaded;
      await inspectSearchService.highlightPdf(
        this.searchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );
    },
    /**
     *
     * @param {*} absoluteIndex
     */
    async showResultOnDocument(absoluteIndex) {
      await inspectSearchService.showResultOnDocument(
        absoluteIndex,
        this.searchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );
    },
    /**
     *
     */
    removeHomeParent() {
      // remove the parent home from the parents array for all elements
      // (the first element in breadcrumbs should be the actual parent folder of the document,
      // otherwise the divider will be shown before the folder name)
      this.searchResults.items.forEach((searchResult) => {
        searchResult.folder_hierarchy = searchResult.parents.filter(
          (parent) => parent.folder_name !== "home"
        );
      });
      // push an empty object for those arrays that are empty (so that the icon will be still shown )
      this.searchResults.items.forEach((searchResult) => {
        if (searchResult.folder_hierarchy.length == 0) {
          searchResult.folder_hierarchy.push({ folder_name: null, folder_id: null });
        }
      });
    },
  },
  async created() {
    this.selectedItem.searchMode = this.searchMode;

    if (this.searchResults.items.length > 0) {
      this.removeHomeParent();
      // load the file
      await inspectSearchService.loadPdf(
        this.searchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );
    } else {
      await this.getPdf(this.documentId);
    }
  },
};
</script>
<style>
.search-container-style {
  display: flex;
  flex-direction: column;

  width: 50%;
}
@media (max-width: 750px) {
  .search-container-style {
    display: flex;
    width: 100%;
  }
}
</style>
