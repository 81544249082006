<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <v-row class="fill-row-height ">
    <v-col
      cols="12"
      class="m-auto p-0 d-flex align-items-center flex-column"
      style="justify-content: space-evenly"
    >
      <div align-self="center" align-h="center">
        <form @submit.prevent="submit">
          <v-card flat class="px-10 py-10" style="width: 100%">
            <v-img :src="logoImage" style="height: 20vh"></v-img>

            <v-card-title class="h4 font-weight-bold mb-1">{{
              this.$filters.translate("ipbrary_003")
            }}</v-card-title>
            <alert
              class="py-2"
              :variant="alertVariant"
              :messageList="alertMessages"
              v-if="showAlert"
            ></alert>
            <v-item-group>
              <v-text-field
                color="primary"
                type="email"
                v-model="user.username"
                label="Email"
                placeholder="Please enter your email"
                prepend-inner-icon="mdi-email"
                variant="outlined"
                required
              ></v-text-field>
            </v-item-group>

            <v-item-group>
              <v-text-field
                color="primary"
                type="password"
                v-model="user.password"
                placeholder="Please enter your password"
                label="Password"
                prepend-inner-icon="mdi-lock"
                variant="outlined"
                required
              ></v-text-field>
            </v-item-group>

            <v-btn
              class="mt-3 mb-3 text-white color-primary"
              style="width: 100%"
              type="submit"
              >Log In</v-btn
            >
          </v-card>
        </form>
      </div>
      <div class="mt-5">
        <metis_footer />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AlertComponent from "../../../components/ui/modals/AlertComponent.vue";
import FooterComponent from "../../../components/ui/FooterComponent.vue";
import { mapActions } from "vuex";
import logoImg from "../../../assets/images/ipbrary_logo-positive_mediun.png";
export default {
  name: "LoginView",

  components: {
    alert: AlertComponent,
    metis_footer: FooterComponent,
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      logoImage: logoImg,
      alertVariant: "warning",
      alertMessages: [],
      showAlert: false,
    };
  },

  methods: {
    ...mapActions(["logIn"]),

    async submit() {
      try {
        const response = await this.logIn(this.user);
        if (response) {
          this.$router.push("/home");
        } else {
          this.showAlert = true;
          this.alertMessages = [
            { text: this.$filters.translate("ipbrary_014") },
          ];
        }
      } catch (error) {
        this.showAlert = true;
        this.alertMessages = [{ text: this.$filters.translate("ipbrary_014") }];
      }
    },
  },
};
</script>

<style>
.full-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
