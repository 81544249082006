<template>
  <v-responsive :max-width="width">
    <div class="m-3" v-if="imagePreview.url">
      <v-card
        class="mx-auto"
        style="overflow: visible"
        height="200"
        max-width="200"
        :image="imagePreview.url"
      >
        <template v-slot:append>
          <v-btn
            class="close-popup"
            icon="mdi-close"
            :alt="imagePreview.fileName"
            @click="resetUploader()"
            size="x-small"
            color="primary"
          ></v-btn>
        </template>
      </v-card>
    </div>

    <v-form @submit.prevent="doLlmSearch" autocomplete="off">
      <v-text-field
        type="text"
        variant="solo"
        class="search-input"
        hide-details
        clearable
        required
        :label="this.$filters.translate('ipbrary_037')"
        v-model="searchQuery"
        prepend-inner-icon="mdi-magnify"
        @click:prepend-inner="doLlmSearch"
        @keydown.enter.prevent="doLlmSearch()"
      >
        <template v-slot:append-inner>
          <v-tooltip text="Search by image">
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                @click="onSearchImageBtnClicked"
                icon="mdi-image-search-outline"
                color="icon-primary"
              >
              </v-icon>
            </template>
          </v-tooltip>

          <v-tooltip text="Reset search" v-if="false">
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                @click="onResetSearchBtnClicked"
                icon="mdi-refresh"
                color="#9D8189"
              >
              </v-icon>
            </template>
          </v-tooltip>
        </template>
      </v-text-field>
    </v-form>

    <v-file-input
      ref="fileUploader"
      @change="onFileChanged"
      accept="image/png, image/jpeg, image/bmp"
      v-show="false"
    >
    </v-file-input>
  </v-responsive>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PatentCollectionSearch",
  props: {
    width: {
      type: Number,
      default: 700,
    },
  },
  computed: {
    /**
     *
     */
    ...mapGetters({
      imageSearchResult: "stateImageSearchResult",
    }),
  },
  data() {
    return {
      searchQuery: "",
      imagePreview: {
        url: null,
        fileName: null,
      },
    };
  },
  watch: {
    /**
     *
     * @param {*} newValue
     */
    async imageSearchResult(newValue) {
      this.searchQuery = newValue;
      if (newValue) this.doLlmSearch();
    },
  },
  methods: {
    /**
     *
     */
    doLlmSearch() {
      this.$emit("doLlmSearch", { searchQuery: this.searchQuery });
    },
    /**
     *
     * @param {*} event
     */
    async onFileChanged(event) {
      const file = event.target.files[0];

      // reset state and file uploader
      event.target.value = null;
      this.$store.commit("resetImageSearchState");

      this.imagePreview.url = URL.createObjectURL(file);
      this.imagePreview.fileName = file.name;

      this.$emit("imageUpload", file);
    },
    /**
     *
     */
    onSearchImageBtnClicked() {
      this.$refs.fileUploader.click();
    },
    /**
     *
     */
    onResetSearchBtnClicked() {
      this.$store.commit("resetLlmSearchState");
      this.$store.commit("resetImageSearchState");
      this.resetImagePreview();
      this.searchQuery = null;
    },
    /**
     *
     */
    resetUploader() {
      this.resetImagePreview();
      this.searchQuery = null;
      this.$store.commit("setLlmSearchQuery", this.searchQuery);
    },
    /**
     *
     */
    resetImagePreview() {
      this.imagePreview.url = null;
      this.imagePreview.fileName = null;
    },
  },
};
</script>

<style>
#preview {
  margin: 2%;
}
.close-popup {
  position: absolute !important;
  right: 0;
  transform: translate(50%, -50%);
  border: 3px solid white !important;
  z-index: 9999;
}
.search-input {
  border: none;
  margin: auto;
  width: 100%;
  padding: 0.5%;
  margin-top: 5px;
}
</style>
