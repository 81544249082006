import axiosWrapper from "../axios/axiosWrapper";

const getDefaultLllmState = () => {
  return {
    componentListResponse: {
      text: null,
      components_list: [],
    },
    llmSearchQuery: null,
    panelData: { panels: [], searchContext: null },
  };
};

// initial state
const state = getDefaultLllmState();

const getters = {
  stateComponentListResponse: (state) => state.componentListResponse,
  stateLlmSearchQuery: (state) => state.llmSearchQuery,
  statePanelData: (state) => state.panelData,
};

const actions = {
  /**
   **
   * @param {*} param0
   * @param {*} param1
   */
  async llmSearch({ commit }, { userPrompt }) {
    const response = await axiosWrapper.get(`/llm_invocation/`, null, {
      user_prompt: userPrompt,
    });
    const componentListResponse = {
      text: response.data.text,
      components_list: response.data.components_list.map((element, index) => {
        return { id: index, name: element };
      }),
    };
    commit("setComponentListResponse", componentListResponse);
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async componentSearch({}, { applicationPrompt, component }) {
    const result = await axiosWrapper.post(`/llm_invocation/`, {
      application_prompt: applicationPrompt,
      component: component,
    });
    return result.data;
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} componentList
   */
  setComponentListResponse(state, componentListResponse) {
    state.componentListResponse = componentListResponse;
  },
  /**
   *
   * @param {*} state
   * @param {*} componentList
   */
  setLlmSearchQuery(state, searchQuery) {
    state.llmSearchQuery = searchQuery;
  },
  /**
   *
   * @param {*} state
   * @param {*} componentList
   */
  setComponentList(state, componentList) {
    state.componentListResponse.components_list = componentList;
  },

  /**
   *
   * @param {*} state
   * @param {*} componentList
   */
  setPanelData(state, panelData) {
    state.panelData = panelData;
  },
  /**,
   *
   * @param {*} state
   */
  resetLlmSearchState(state) {
    Object.assign(state, getDefaultLllmState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
