import axiosWrapper from "../axios/axiosWrapper";

const getDefaultFolderState = () => {
  return {
    homeId: null,
    rootFolder: null,
    subFolders: null,
    currentFolderId: null,
    breadcrumbs: [],
  };
};

// initial state
const state = getDefaultFolderState();

const getters = {
  stateHomeId: (state) => state.homeId,
  stateRootFolder: (state) => state.rootFolder,
  stateSubFolders: (state) => state.subFolders,
  stateCurrentFolderId: (state) => state.currentFolderId,
  stateBreadcrumbs: (state) => state.breadcrumbs,
};

const actions = {
  /**
   *
   */
  async home({ commit, state }) {
    let data = await axiosWrapper.get("/folder/home", null, null, {
      "Content-Type": "application/json",
    });

    commit("setRootFolder", data.data.elements[0]);
    commit("setHomeId");
    commit("setCurrentFolderId", state.homeId);
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} data
   */
  setRootFolder(state, rootFolder) {
    state.rootFolder = rootFolder;
  },
  /**
   *
   * @param {*} state
   */
  setHomeId(state) {
    if (state.rootFolder) state.homeId = state.rootFolder.folder_id;
  },
  /**
   *
   * @param {*} state
   * @param {*} data
   */
  setSubFolders(state, data) {
    state.subFolders = data;
  },

  /**
   *
   * @param {*} state
   * @param {*} folderId
   */
  setCurrentFolderId(state, folderId) {
    state.currentFolderId = folderId;
  },
  /**
   *
   * @param {*} state
   * @param {*} breadcrumbs
   */
  setBreadcrumbs(state, breadcrumbs) {
    if (breadcrumbs instanceof Array) {
      state.breadcrumbs = breadcrumbs;
    } else {
      if (state.breadcrumbs) {
        state.breadcrumbs.push(breadcrumbs);
      } else {
        state.breadcrumbs = new Array(breadcrumbs);
      }
    }
  },

  /**
   *
   * @param {*} state
   * @param {*} breadcrumbs
   */
  resetBreadcrumb(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },

  /**
   *
   * @param {*} state
   */
  resetFolderState(state) {
    Object.assign(state, getDefaultFolderState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
