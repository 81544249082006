<template>
  <v-row no-gutters class="h-100 bg-page-primary overflow-auto">
    <v-col cols="12" class="d-flex flex-column h-100">
      <v-btn
        to="/componentSearch"
        variant="plain"
        prepend-icon="mdi-arrow-left"
        class="p-auto mx-4 my-2 text-decoration-none"
        width="250"
        rounded
      >
        {{ this.$filters.translate("ipbrary_042") }}
      </v-btn>
      <inspectSearch
        v-model="querySearchResults"
        :fileName="selectedItem.fileName"
        :searchQuery="searchQuery"
        :pdfBinary="pdfBinary"
        :searchResults="querySearchResults"
        :customStyle="true"
        v-on:updateRating="updateRating"
        v-on:keySentencesLoadedInspect="setKeySentencesLoaded"
        v-on:highlightResult="showResultOnDocument"
      />

      <errorModalCmp
        :title="this.$filters.translate('ipbrary_017')"
        :content="this.errorModalText"
        :okButtonLabel="this.$filters.translate('ipbrary_020')"
        :cancelButtonLabel="this.$filters.translate('ipbrary_019')"
        v-model="errorModal"
        v-on:ok="errorModal = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inspectSearchService from "../../components/search/inspectSearch/services/inspectSearchService.js";

import ModalComponent from "../../components/ui/modals/ModalComponent.vue";
import InspectSearchComponent from "../../components/search/inspectSearch/InspectSearchComponent.vue";

export default {
  name: "SearchQuery",
  components: {
    inspectSearch: InspectSearchComponent,
    errorModalCmp: ModalComponent,
  },
  computed: {
    ...mapGetters({
      querySearchResults: "stateQuerySearchResults",
      breadcrumbsList: "stateBreadcrumbs",
      pdfBinary: "statePdfBinary",
    }),
  },
  props: {
    searchQueryId: {
      default: null,
      type: Number,
    },
    documentId: {
      default: null,
      type: String,
    },
    createPayload: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      keySentencesLoaded: 10, // this is the same step as in the search result component
      errorModalText: this.$filters.translate("ipbrary_051"),
      errorModal: false,
      selectedItem: {
        fileName: null,
        page: null,
        document_id: null,
        keySentence: null,
        searchMode: null,
      },
    };
  },
  methods: {
    ...mapActions(["highlightedPdf", "getPdf"]),
    /**
     *
     * @param {*} param0
     */
    updateRating({ rate, index }) {
      this.querySearchResults.items[index].rating = rate;
    },
    /**
     *
     * @param {*} keySentencesLoaded
     */
    async setKeySentencesLoaded(keySentencesLoaded) {
      // every time the results are expanded,
      // update progressively the highlighted text
      this.keySentencesLoaded = keySentencesLoaded;
      await inspectSearchService.highlightPdf(
        this.querySearchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );
    },
    /**
     *
     * @param {*} absoluteIndex
     */
    async showResultOnDocument(absoluteIndex) {
      await inspectSearchService.showResultOnDocument(
        absoluteIndex,
        this.querySearchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );
    },
  },
  async created() {
    //set document vars
    try {
      this.searchQuery = window.history.state.searchQuery;

      // we are in one search query, search types are all the same
      this.selectedItem.searchMode =
        this.querySearchResults.items[0].search_type;

      if (this.querySearchResults.items.length > 0) {
        // load the file
        await inspectSearchService.loadPdf(
          this.querySearchResults.items,
          this.selectedItem,
          this.keySentencesLoaded,
          this.highlightedPdf,
          this.createPayload
        );
        // empty search results
      } else {
        //if we are in pdf mode fetch the pdf
        if (this.documentId) {
          // set also the name, take it from pathArray because results are empty
          await this.getPdf(this.documentId);
        } else {
          // reset pdf
          this.$store.commit("setPdfBinary", new ArrayBuffer(0));
        }
      }
    } catch (error) {
      this.errorModal = true;
    }
  },
};
</script>
