<template>
  <v-row class="fill-row-height bg-primary h-100 m-auto">
    <v-col
      cols="12"
      class="p-0 d-flex align-items-center flex-column"
      style="justify-content: space-between"
    >
      <div class="contact-card">
        <v-card class="p-4 h-100">
          <v-card-title class="h4 m-auto">Contact us </v-card-title>
          <v-card-text class="p-4" style="text-align: justify">
            <span>Email: </span>
            <a href="mailto: info@metisai.it">info@metisai.it</a>
          </v-card-text>
        </v-card>
      </div>
      <div class="mt-5">
        <metis_footer :fontColor="'text-primary-ligthen-1'" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FooterComponent from "@/components/ui/FooterComponent.vue";
export default {
  name: "ContactView",
  components: {
    metis_footer: FooterComponent,
  },
};
</script>
<style>
.contact-card {
  height: 40%;
  margin-top: 4%;
  margin-bottom: 4%;
  padding: 2%;
  width: 80%;
}
</style>
