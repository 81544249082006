import store from "@/store";
import Login from "@/views/auth/ipbrary/LoginView";
import Home from "@/views/home/HomeView";
import PageNotFound from "@/views/PageNotFound";
import PrivacyView from "@/views/legal/PrivacyView";
import TermsConditionsView from "@/views/legal/TermsConditionsView";
import ContactView from "@/views/legal/ContactView";
import LandingSearch from "@/views/search/landingSearch/LandingSearch";
import SearchResults from "@/views/search/simpleSearch/SearchResults";
import SearchQueryResults from "@/views/searchQueryResults/SearchQueryResults";
import ComponentListView from "@/views/search/componentSearch/componentCollection/ComponentListView";
import ComponentSearchView from "@/views/search/componentSearch/componentResults/ComponentSearchView";
import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from "vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      breadCrumb: [
        {
          title: "Home",
        },
      ],
    },
  },
  {
    path: "/landing-search",
    name: "LandingSearchView",
    component: LandingSearch,
    props: route => ({ patentCollection: route.query.patentCollection === 'false' }),
    meta: {
      requiresAuth: false,
      breadCrumb: [
        {
          title: "Search Overview",
        },
      ],
    },
  },
  {
    path: "/search/:search_id/folder/:folder_id/document/:name",
    name: "DocumentSearchResults",
    component: SearchResults,
    meta: { requiresAuth: true },
    props: (route) => ({
      folder_id: Number(route.params.folder_id),
      name: route.params.name,
    }),
  },
  {
    path: "/search/:search_id/folder/:folder_id",
    name: "FolderSearchResults",
    component: SearchResults,
    meta: { requiresAuth: true },
    props: (route) => ({ folder_id: Number(route.params.folder_id) }),
  },
  {
    path: "/search_query/:search_query_id/search_results/",
    name: "SearchQueryResults",
    component: SearchQueryResults,
    meta: { requiresAuth: true },
    props: (route) => ({
      searchQueryId: Number(route.params.search_query_id),
      searchQuery: route.params.searchQuery,
    }),
  },
  {
    path: "/components",
    name: "ComponentList",
    component: ComponentListView,
  },
  {
    path: "/componentSearch",
    name: "ComponentSearch",
    component: ComponentSearchView,
  },
  {
    path: "/privacy_policy",
    name: "Privacy",
    component: PrivacyView,
  },
  {
    path: "/terms_conditions",
    name: "TermsConditions",
    component: TermsConditionsView,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/",
    redirect: { name: "Home" },
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    name: "PageNotFound",
  },
];

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes: routes,
  // ...
});

// Navigation Guard: redirect to the /login route if not authenticated
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);
  const isUserLoggedIn = store.getters.isAuthenticated;

  if (requiresAuth) {
    if (isUserLoggedIn) {
      next();
    } else {
      next("/login");
    }
  } else if (requiresGuest) {
    if (isUserLoggedIn) {
      next("/home");
    } else {
      next();
    }
  } else {
    next();
  }
});

// Navigation Guard: change page title
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to.meta.title || "IPBrary";
  });
});

export default router;
