import axiosWrapper from "../axios/axiosWrapper";

const getDefaultUserState = () => {
  return {
    user: null,
    freeSpace: null,
    language: "en",
  };
};

// initial state
const state = getDefaultUserState();

const getters = {
  isAuthenticated: (state) => !!state.user,
  stateUser: (state) => state.user,
  freeSpace: (state) => state.freeSpace,
  language: (state) => state.language,
};

const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} user
   * @returns
   */
  async logIn({ dispatch }, user) {
    const formData = {
      username: user.username,
      password: user.password,
    };

    console.log(formData)

    const loginResponse = await axiosWrapper.postForm("/login", formData);

    if (loginResponse.status == 200) {
      await dispatch("viewMe");
    }

    return this.state.users.user;
  },
  /**
   *
   * @param {*} param0
   */
  async viewMe({ commit }) {
    let { data } = await axiosWrapper.get("/user/whoami");

    commit("setUser", data);
    commit("setLanguage", null);
  },
  /**
   *
   * @param {*} param0
   */
  // eslint-disable-next-line no-empty-pattern
  async logOut({}, username) {
    //reset state
    //reset auth token
    await axiosWrapper.get("/:username/logout", { username: username });
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} user
   */
  setUser(state, user) {
    state.user = user;
  },
  /**
   *
   * @param {*} state
   * @param {*} user
   */
  resetUserState(state, user) {
    state.user = user;
  },
  /**
   *
   * @param {*} state
   */
  setLanguage(state, language) {
    if (language) {
      state.language = language;
    } else {
      state.language = "en";
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
