function translations() {
  return {
    en: {
      ipbrary_001: "Account",
      ipbrary_002: "Feedback",
      ipbrary_003: "Log in to your account",
      ipbrary_004: "Log out",
      ipbrary_005: "IPBrary is a link[Metis] product",
      ipbrary_006: "404 Page not found",
      ipbrary_007: "Go back",
      ipbrary_008: "Search in progress...",
      ipbrary_009: "Your search returned no results!",
      ipbrary_010: "Search results",
      ipbrary_011: "Load more...",
      ipbrary_012:
        "Need other results? Try formulating a longer and more specific question",
      ipbrary_013: "Copy to clipboard",
      ipbrary_014:
        "You cannot login in to IPBrary. Please contact info@metisai.it ",
      ipbrary_015: "Search failed",
      ipbrary_016:
        "Your search failed. You can report this issue through the Feedback form on the toolbar.",
      ipbrary_017: "Something went wrong",
      ipbrary_018: "Copied!",
      ipbrary_019: "Cancel",
      ipbrary_020: "OK",
      ipbrary_021: "You searched for _placeholder",
      ipbrary_022: "New Component",
      ipbrary_023: "Create new component",
      ipbrary_024: "Add component",
      ipbrary_025: "Enter a component name",
      ipbrary_026: "Add",
      ipbrary_027: "Required",
      ipbrary_028: "A component with this name already exists",
      ipbrary_029: "Invalid character detected",
      ipbrary_030: "Delete",
      ipbrary_031: "Do you really want to delete this",
      ipbrary_032: "The following items will be deleted:",
      ipbrary_033: "Actions",
      ipbrary_034: "Back to the homepage",
      ipbrary_035: "Patent Collection",
      ipbrary_036: "Search with AI",
      ipbrary_037: "Generate list of components for a given application",
      ipbrary_038: "Component",
      ipbrary_039: "Search Patents",
      ipbrary_040: "Back to component list",
      ipbrary_041: "Delete selected items",
      ipbrary_042: "Back to component search",
      ipbrary_043: "Delete component",
      ipbrary_044: "Edit component",
      ipbrary_045: "Delete patent",
      ipbrary_046: "Move patent",
      ipbrary_047: "Search in this component",
      ipbrary_048: "Search EPO Patents",
      ipbrary_049: "AI Powered Search",
      ipbrary_050:
        "Type a sentence of at least four words to enable semantic search",
      ipbrary_051:
        "The document cannot be loaded. You can report this issue through the Feedback form on the toolbar.",
      ipbrary_052: "Patent collection for _placeholder ",
      ipbrary_053: "Search keywords",
    },
  };
}
/**
 *
 * @param {*} id
 * @returns
 */
function getTranslatedText(language, id) {
  const allTranslations = translations();
  const translationsByLanguage = allTranslations[language];
  const text = translationsByLanguage[id];

  return text ? text : "missing_text";
}

export { getTranslatedText };
