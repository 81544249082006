<template>
  <div>
    <v-alert
      :type="this.variant"
      :closable="closable"
      :density="density"
      v-model="alert"
    >
      <template v-slot:text>
        <div v-for="message in messageList" :key="message">
          <p
            v-if="message.links"
            v-html="
              this.$filters.formatLink(
                message.text,
                message.links,
                'font-weight-regular text-blue'
              )
            "
          ></p>
          <p v-if="!message.links">
            {{ message.text }}
          </p>
        </div>
      </template>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    messageList: null,
    variant: null,
    link: null,
    closable: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: "default",
    },
  },
  watch: {
    link: {
      handler() {},
      deep: true,
    },
  },
  data() {
    return {
      alert: true,
    };
  },
};
</script>
