<template>
  <div v-if="breadcrumbsToShow">
    <v-breadcrumbs
      v-model="breadList"
      density="compact"
      :class="{
        'm-0 p-1 font-weight-bold w-auto d-block': mode !== 'mini',
        'm-0 p-0 w-auto d-block': mode === 'mini',
      }"
      :items="breadcrumbsToShow"
      :color="customColor"
    >
      <template v-slot:prepend>
        <v-icon size="medium" v-if="prependIcon" :icon="prependIcon"></v-icon>
      </template>

      <template v-slot:title="{ item }">
        <v-breadcrumbs-item class="p-0 m-0" :to="item.to" exact
          ><span>
            {{ item.title }}
          </span>
        </v-breadcrumbs-item>
      </template>

      <template v-slot:divider>
        <span class="p-0 m-0" v-if="!dividerIcon">/</span>
        <v-icon v-if="dividerIcon">{{ dividerIcon }}</v-icon>
      </template>
    </v-breadcrumbs>

    <span></span>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  data() {
    return {
      breadCrumbsItems: [],
    };
  },
  props: {
    breadcrumbsProps: {},
    disableLinks: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "normal",
    },
    dividerIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    customColor: {
      type: String,
      default: "contrast",
    },
  },
  watch: {
    breadcrumbsProps: {
      handler(newList) {
        this.setBreadCrubms(newList);
      },
      deep: true,
    },
  },
  methods: {
    /**
     *
     * @param {*} breadList
     */
    setBreadcrumbsListCompact(breadList) {
      let breadcrumbsListShort = [];

      breadcrumbsListShort.push(breadList[0]);
      const name = "Content";
      let intermediateBreadcrumb = {
        title: "...",
        disabled: this.disableLinks,

        to: {
          name: name,
          params: {
            id: breadList[breadList.length - 4].folder_id,
          },
        },
        folder_id: breadList[breadList.length - 4].folder_id,
      };
      breadcrumbsListShort.push(intermediateBreadcrumb);

      breadcrumbsListShort.push(breadList[breadList.length - 3]);

      breadcrumbsListShort.push(breadList[breadList.length - 2]);

      breadcrumbsListShort.push(breadList[breadList.length - 1]);

      this.breadcrumbsToShow = breadcrumbsListShort;
    },
    /**
     *
     * @param {*} newList
     */
    setBreadCrubms(newList) {
      if (newList && newList.length >= 6) {
        this.setBreadcrumbsListCompact(newList);
      } else if (newList) {
        this.breadcrumbsToShow = newList;
      }
    },
    getOpacity(item) {
      if (item.disabled) {
        return "opacity: 1.0 !important";
      }
    },
  },

  computed: {
    breadList() {
      return this.breadcrumbsProps;
    },
    breadcrumbsToShow: {
      set(value) {
        this.breadCrumbsItems = value;
      },
      get() {
        return this.breadCrumbsItems;
      },
    },
  },
  created() {
    this.setBreadCrubms(this.breadcrumbsProps);
  },
};
</script>
<style>
body
  > div
  > div
  > div.v-col.v-col-12.mx-auto.px-3.mt-3
  > div
  > ul
  > li.v-breadcrumbs-item.v-breadcrumbs-item--disabled.text-black {
  opacity: 1 !important;
}

.v-breadcrumbs-divider {
  padding: 1px !important;
}
.v-breadcrumbs-item {
  padding: 3px !important;
}
a.v-breadcrumbs-item--disabled {
  color: v-bind(customColor);
  opacity: 1 !important;
}
</style>
